import React, {useEffect, useState, useCallback} from 'react';
import './style.css';
import {useQueryMap} from '../../../hooks/useQueryParams';
import Checkbox from '../../../components/Checkbox';
import {BackButton, NextButton} from '../../../components/Buttons';
import {httpRequest} from '../../../utils/aws-services';
import TemplatePreview from '../../../components/TemplatePreview';
import {BaseButton} from '../../../components/Buttons/BaseButton';

interface Service {
  label: string;
  value: boolean;
}

interface Services {
  [key: string]: Service;
}

const AccountWizard: React.FC<{onSubmitAccount: () => void}> = ({
  onSubmitAccount,
}) => {
  const [accountName, setAccountName] = useState('');
  // const [cfTemplate, setCfTemplate] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [accountId, setAccountId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [templatePreview, setTemplatePreview] = useState<string | null>(null);
  const [cloudFormationUrl, setCloudformationUrl] = useState<string | null>(
    null,
  );
  const [awsServices, setAwsServices] = useState<Services>({
    s3: {label: 'S3', value: true},
    lambda: {label: 'Lambda', value: true},
    dynamodb: {label: 'DynamoDB', value: true},
    ec2: {label: 'EC2', value: true},
    ecs: {label: 'ECS', value: true},
    eks: {label: 'EKS', value: true},
    iam: {label: 'IAM', value: true},
    secretsmanager: {label: 'Secrets Manager', value: true},
    logs: {label: 'CloudWatch', value: true},
  });

  const {
    navigate,
    params: queryParams,
    change: changeQueryParams,
  } = useQueryMap();
  const stepFromQuery = parseInt(queryParams.step || '1', 10);

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    changeQueryParams({step: nextStep.toString()});
  };

  const goToPreviousStep = () => {
    const prevStep = Math.max(1, currentStep - 1);
    changeQueryParams({step: prevStep.toString()});
  };

  const launchStackInTheCustomerAccount = async () => {
    const url =
      `https://console.aws.amazon.com/cloudformation/home?region=eu-west-1#/stacks/create/review?templateURL=${cloudFormationUrl}&stackName=inframap-analyzer-${localStorage
        .getItem('username')
        ?.replace('_', '-')}` as string;
    window.open(url, '_blank');
    goToNextStep();
  };
  const sectionClass = 'flex flex-col items-center w-full max-w-2xl h-auto';

  const generateCloudformation = useCallback(async () => {
    try {
      const services: any = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(awsServices)) {
        services[key] = (awsServices as any)[key].value;
      }
      if (!accountId) throw new Error('Account id not provided');
      const {s3Url, template} = await httpRequest(
        'customer-stack',
        'POST',
        undefined,
        {
          accountId,
          services,
        },
      );
      setTemplatePreview(template);
      setCloudformationUrl(s3Url);
    } catch (error) {
      console.error('Error generating CloudFormation:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsServices, accountId]);

  const createNewAccount = async () => {
    await httpRequest('account', 'POST', undefined, {
      accountName,
      accountId,
      cfTemplateS3Url: cloudFormationUrl,
    });
    navigate('/accounts');
  };

  const deleteAccount = async () => {
    await httpRequest('account', 'DELETE', undefined, {
      accountName,
      accountId,
    });
    navigate('/accounts');
  };

  const validateAndSubmitAccountId = () => {
    if (accountId) {
      const isValidAccountId = /^\d{12}$/.test(accountId);
      if (!isValidAccountId) {
        setErrorMessage(
          'Account ID must be 12 digits and contain only numbers',
        );
        return;
      }
      createNewAccount()
        .then(() => {
          onSubmitAccount();
        })
        .catch(e => {
          setErrorMessage(e.message);
        });
    }
  };

  const handleAccountIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure only numbers are entered
    setErrorMessage(null);
    const numericValue = e.target.value.replace(/[^\d]/g, '');
    setAccountId(numericValue);
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    setAccountName(e.target.value);
  };

  const handleCheckboxChange = (key: string) => {
    setAwsServices(prevServices => ({
      ...prevServices,
      [key]: {
        ...prevServices[key],
        value: !prevServices[key].value,
      },
    }));
  };

  useEffect(() => {
    setCurrentStep(stepFromQuery);
  }, [stepFromQuery]);

  return (
    <div className="max-width: 400px; max-height: 400px;">
      <div className="flex flex-col items-center justify-center p-4" />
      {currentStep === 1 && (
        <section className={sectionClass}>
          <h2 className="text-xl font-bold mb-2 p-3">
            Generate Cloudformation
          </h2>
          <p className="text-center p-3">
            This process ensures our service has minimal, controlled access to
            your resources for visualization purposes.
          </p>
          <input
            type="text"
            pattern="\d{12}"
            value={accountId || ''}
            placeholder="Account ID"
            onChange={handleAccountIdChange}
            maxLength={12}
            className="no-arrows p-2 border rounded-md mb-4 w-64 focus:outline-none focus:border-indigo-600"
          />
          <div className="mb-5">
            <h3 className="text-lg font-bold mb-2 p-3">AWS Services</h3>
            <div className="space-y-5">
              {Object.entries(awsServices).map(([key, value]) => {
                return (
                  <div
                    className="relative flex items-start"
                    key={`service-${key}`}>
                    <Checkbox
                      checked={value.value}
                      checkboxTitle={value.label}
                      checkboxDescription=""
                      onChange={() => handleCheckboxChange(key)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-between pt-6">
            {currentStep < 2 && (
              <NextButton
                disabled={!accountId}
                onClick={() => {
                  goToNextStep();
                  generateCloudformation();
                }}
              />
            )}
          </div>
        </section>
      )}
      {currentStep === 2 && (
        <section className={sectionClass}>
          {/* <h2 className="text-xl font-bold mb-2 p-3">Setup New AWS Account</h2> */}
          <br />
          {templatePreview && (
            <TemplatePreview templateText={templatePreview} />
          )}
          <BaseButton
            type="button"
            text="Launch CloudFormation"
            disabled={!templatePreview}
            onClick={launchStackInTheCustomerAccount}
          />
          <div className="flex">
            <div className="flex justify-between pt-6 p-3">
              {currentStep > 1 && (
                <BackButton
                  onClick={() => {
                    goToPreviousStep();
                    setTemplatePreview(null);
                  }}
                />
              )}
            </div>
            <div className="flex justify-between pt-6 p-3">
              {currentStep > 1 && (
                <NextButton
                  disabled={!templatePreview}
                  onClick={goToNextStep}
                />
              )}
            </div>
          </div>
        </section>
      )}

      {currentStep === 3 && (
        <section className={sectionClass}>
          <h2 className="text-xl font-bold mb-2 p-4">Provide Account Name</h2>
          <p className="mb-3">Fill out Account Name and Account ID</p>
          <input
            type="text"
            value={accountName}
            placeholder="Account Name"
            onChange={handleAccountNameChange}
            maxLength={36}
            className="no-arrows p-2 border rounded-md mb-4 w-64 focus:outline-none focus:border-indigo-600"
          />

          {errorMessage && (
            <p className="bg-red-200 text-red-700 p-2 mb-4 rounded-md max-w-md text-center">
              {errorMessage}
            </p>
          )}
          <BaseButton
            className="mt-5"
            type="button"
            text="Register New Account"
            onClick={validateAndSubmitAccountId}
          />
          <div className="flex justify-between pt-6">
            {currentStep > 1 && <BackButton onClick={goToPreviousStep} />}
          </div>
        </section>
      )}
    </div>
  );
};

export default AccountWizard;
