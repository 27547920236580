import {Outlet, useLocation, useNavigate} from 'react-router';
import React, {useCallback, useEffect, useState} from 'react';
import {authService} from '../utils/authentication';

export const AuthResolver: React.FC<{}> = () => {
  const token = localStorage.getItem('refresh-token');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => {
    if (location.pathname.indexOf('auth') === -1) {
      navigate('/auth/login');
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      authService
        .useRefreshToken(token)
        .then(async () => {
          setIsLoggedIn(true);
          if (location.pathname.indexOf('auth') > -1) {
            navigate('/');
          }
        })
        .catch(e => {
          console.error(e);
          navigateToLogin();
          localStorage.clear();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigateToLogin();
    }
  }, [location.pathname, navigate, navigateToLogin, token]);

  return <Outlet />;
};
