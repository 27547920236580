import {LambdaClient} from '@aws-sdk/client-lambda';
import {DynamoDBClient, QueryCommand} from '@aws-sdk/client-dynamodb';
import {S3} from '@aws-sdk/client-s3';
import {unmarshall} from '@aws-sdk/util-dynamodb';
import {SignatureV4} from '@aws-sdk/signature-v4';
import {Sha256} from '@aws-crypto/sha256-js';
import {HttpRequest} from '@aws-sdk/protocol-http';
import {authService, getIamCredentials} from './authentication';
import {AccountInfo, BoardModel, UserData} from './types';
import {APP_CONFIG} from '../config/config-builder';
import {ErrorCodes} from './error-codes';

export const lambda = new LambdaClient({
  region: APP_CONFIG.awsConfig.region,
  credentials: async () => getIamCredentials(),
});

export const dynamodb = new DynamoDBClient({
  region: APP_CONFIG.awsConfig.region,
  credentials: async () => getIamCredentials(),
});

export const s3 = new S3({
  region: APP_CONFIG.awsConfig.region,
  credentials: async () => getIamCredentials(),
});

export enum LambdaFunctions {
  USER_OPERATIONS,
  DIAGRAM_GENERATOR,
}

export const functionsMapping = {
  [LambdaFunctions.USER_OPERATIONS]: APP_CONFIG.awsConfig.userOperationsLambda,
  [LambdaFunctions.DIAGRAM_GENERATOR]:
    APP_CONFIG.awsConfig.diagramGenerationLambda,
};

const signer = new SignatureV4({
  region: APP_CONFIG.awsConfig.region,
  service: 'execute-api',
  credentials: async () => getIamCredentials(),
  sha256: Sha256,
});

export const httpRequest = async <T>(
  endpoint: string,
  method: 'POST' | 'GET' | 'PUT' | 'HEAD' | 'DELETE',
  headers: {[key: string]: string} = {},
  body?: any,
  handlers?: {[key in ErrorCodes]?: (response: any) => Promise<any>},
) => {
  const [protocol, nada, hostname, stageName] =
    APP_CONFIG.awsConfig.apiGatewayUrl.split('/');
  const command = new HttpRequest({
    method,
    protocol: 'https',
    hostname,
    path: `/${stageName}/${endpoint}`,
    headers: {
      Host: hostname,
      'Content-Type': 'application/json',
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  const signedRequest = await signer.sign(command, {signingDate: new Date()});
  const r = await fetch(`${APP_CONFIG.awsConfig.apiGatewayUrl}/${endpoint}`, {
    method: signedRequest.method,
    body: signedRequest.body,
    headers: signedRequest.headers,
  });
  const response = await r.json();
  const {code} = response;
  if (r.status >= 400 && handlers) {
    const handler = handlers[code as ErrorCodes];
    if (handler) {
      handler(response);
    }
    throw new Error(JSON.stringify(response));
  }
  return response;
};

export enum PersonalDataType {
  ACCOUNT = 'ACCOUNT',
  BOARD = 'BOARD',
  USER_DATA = 'USER#DATA',
}

export type DynamoResultDataTypeMap = {
  [PersonalDataType.ACCOUNT]: AccountInfo;
  [PersonalDataType.BOARD]: BoardModel;
  [PersonalDataType.USER_DATA]: UserData;
};

export const getPersonalData = async <T extends keyof DynamoResultDataTypeMap>(
  dataType: T,
  id?: string,
): Promise<DynamoResultDataTypeMap[T][]> => {
  const username = localStorage.getItem('username');
  await authService.useRefreshToken();
  if (!username) throw new Error('Identity not found!');
  const fetchedAccounts = await dynamodb.send(
    new QueryCommand({
      TableName: APP_CONFIG.awsConfig.registryTableName,
      KeyConditionExpression: '#pk = :pkVal AND begins_with(#sk, :skVal)',
      ExpressionAttributeNames: {
        '#pk': 'pk',
        '#sk': 'sk',
      },
      ExpressionAttributeValues: {
        ':pkVal': {S: username},
        ':skVal': {S: `${dataType}${id ? `#${id}` : ''}`},
      },
    }),
  );
  return (fetchedAccounts.Items ?? []).map(
    i => unmarshall(i) as DynamoResultDataTypeMap[T],
  );
};
