import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {EnvelopeIcon, KeyIcon} from '@heroicons/react/20/solid';
import {useDispatch} from 'react-redux';
import {authService} from '../../utils/authentication';
import ErrorMessage from '../../components/ErrorMessage';
import Input from '../../components/InputButton';
import {setConfirmEmailWarning, setUser} from '../../store/userSlice';
import {BaseButton} from '../../components/Buttons/BaseButton';

const Signup: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const signup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await authService.signup(email, password);
      // You might want to redirect the user or give some other indication of success here
      if (result.$metadata.httpStatusCode === 200) {
        dispatch(setUser({email}));
        dispatch(setConfirmEmailWarning(true));
        navigate('/auth/login');
      }
      // result obj. example
      //   {
      //     "$metadata": {
      //         "httpStatusCode": 200,
      //         "requestId": "9efe2d01-8bdc-47d5-be04-2d8786a5eb9c",
      //         "attempts": 1,
      //         "totalRetryDelay": 0
      //     },
      //     "CodeDeliveryDetails": {
      //         "AttributeName": "email",
      //         "DeliveryMedium": "EMAIL",
      //         "Destination": "s***@t***"
      //     },
      //     "UserConfirmed": false,
      //     "UserSub": "bbc499f0-7ed9-452c-a32c-bd8274a0d948"
      // }
    } catch (error) {
      console.log(error);
      console.error('Signup failed:', error);
      const errorMsg = error!.toString().split(':');
      setErrorMessages([errorMsg[errorMsg.length - 1]]);
      setShowWarning('Signup failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up to InfraMap
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={signup}>
          <div>
            <Input
              label="Email"
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
              required
              icon={
                <EnvelopeIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div>
            <Input
              label="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
              required
              icon={
                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              }
            />
          </div>
          <div>
            <BaseButton
              loading={loading}
              text="Sign Up"
              type="submit"
              className="w-full"
            />
          </div>
        </form>
        {showWarning && (
          <div className="pt-4">
            <ErrorMessage
              onDismiss={() => setShowWarning('')}
              errors={errorMessages}
            />
          </div>
        )}
        <p className="mt-10 text-center text-sm text-gray-500">
          Already a member?{' '}
          <button
            type="button" // Changed from <a> to <button>
            className="font-semibold leading-6 text-green-600 hover:text-green-500"
            onClick={() => {
              navigate('/auth/login');
            }}>
            Sign In
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signup;
