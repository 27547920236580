import {useCallback, useState} from 'react';
import {ContextMenuItem} from './types';
// eslint-disable-next-line import/no-cycle
import {ContextMenuDialog} from './ContextMenuDialog';
import './style.css';

export const MenuItem: React.FC<ContextMenuItem> = ({
  label,
  value,
  onClick,
  subItems,
}) => {
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const [closeSubMenuTask, setSubMenuTask] = useState<NodeJS.Timeout>();

  const openDialog = useCallback(() => {
    if (closeSubMenuTask) {
      clearTimeout(closeSubMenuTask);
      setSubMenuTask(undefined);
    }
    setSubMenuVisible(true);
  }, [closeSubMenuTask]);

  const closeDialog = useCallback(() => {
    setSubMenuTask(
      setTimeout(() => {
        setSubMenuVisible(false);
      }, 800),
    );
  }, []);

  return (
    <button
      value={value}
      className="context-menu-item"
      type="button"
      onClick={onClick}
      onMouseEnter={openDialog}
      onMouseLeave={closeDialog}>
      {label}
      {subItems && subMenuVisible ? (
        <ContextMenuDialog
          items={subItems}
          style={{
            position: 'absolute',
            top: '0',
            fontSize: '15px',
            width: '400px',
            left: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            padding: '10px',
            zIndex: 1001,
          }}
        />
      ) : null}
    </button>
  );
};
