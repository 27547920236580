import React, {useCallback, useMemo} from 'react';
import {PlusIcon} from '@heroicons/react/24/outline';
import './style.css';
import {useAccounts} from '../../hooks/useAccounts';
import {Account} from './Account/Account';
import {BaseButton} from '../../components/Buttons/BaseButton';
import {httpRequest} from '../../utils/aws-services';
import {NewAccountDialog} from '../../dialogs/NewAccount/NewAccount';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {useQueryMap} from '../../hooks/useQueryParams';

const Accounts: React.FC = () => {
  const {accounts, loading, refresh} = useAccounts();
  const {setValues} = useDialogConfig();
  const {
    params: {search},
  } = useQueryMap();
  const list = useMemo(() => {
    const items = accounts.filter(
      a => !search || a.accountName.indexOf(search) > -1,
    );
    if (items.length === 0 && !loading) {
      return <h1 className="mt-5 px-2">No accounts found</h1>;
    }
    return accounts.map(account => (
      <Account
        account={account}
        key={account.accountId}
        onDelete={async () => {
          await httpRequest('account', 'DELETE', undefined, {
            accountId: account.accountId,
          });
          refresh();
        }}
      />
    ));
  }, [accounts, loading, refresh, search]);

  const newAccountDialog = useCallback(() => {
    setValues({
      openDialog: <NewAccountDialog onSubmit={async () => refresh()} />,
    });
  }, [refresh, setValues]);

  return (
    <div className="flex flex-col accounts-container">
      <div className="topbar">
        <h1 className="text-4xl font-bold">Accounts</h1>
        <BaseButton
          onClick={newAccountDialog}
          className="new-account mt-5"
          icon={<PlusIcon style={{width: 25, height: 25}} />}
          text="New Account"
          type="button"
        />
      </div>
      {list}
    </div>
  );
};

export default Accounts;
