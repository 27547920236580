import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sidebarOpen: false,
  routes: [
    {
      name: 'Boards',
      href: '/',
      iconName: 'WindowIcon',
      current: true,
      roles: ['admin', 'user'],
    },
    {
      name: 'Accounts',
      href: '/accounts',
      iconName: 'FolderIcon',
      current: false,
      roles: ['admin'],
    },
  ],
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleSidebar: state => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    setCurrentRoute: (state, action) => {
      state.routes.forEach(route => {
        route.current = false;
      });
      const route = state.routes.find(
        stateRoute => stateRoute.href === action.payload,
      );
      if (route) {
        route.current = true;
      }
    },
  },
});

export const {toggleSidebar, setCurrentRoute} = navigationSlice.actions;

export default navigationSlice.reducer;
