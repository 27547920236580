/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {MagnifyingGlassIcon, PlusIcon} from '@heroicons/react/24/outline';
import MetadataSidebar from './MetadataSidebar';
import {useSidebarsConfig} from '../../contexts/SidebarsConfigProvider';
import {GroupType} from '../../utils/types';
import {SidebarNames} from '../../contexts/SidebarsConfigProvider/utils';
import {BoardViewer, MAX_ZOOM, MIN_ZOOM} from '../BoardViewer';
import {BoardActions} from './BoardActions/BoardActions';
import {useQueryMap} from '../../hooks/useQueryParams';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {Snackbar} from '../Snackbar/Snackbar';
import './style.css';
import {User} from '../User/User';
import Input from '../InputButton';
import {BaseButton} from '../Buttons/BaseButton';

export const BoardLayout: React.FC<{}> = () => {
  const {config, setValues} = useSidebarsConfig();
  const {config: dialogConfig, setValues: setDialog} = useDialogConfig();

  const dialog = useMemo(() => {
    if (dialogConfig.openDialog) {
      return dialogConfig.openDialog;
    }
    if (dialogConfig.openSnackbar) {
      return <Snackbar {...dialogConfig.openSnackbar} />;
    }
    return null;
  }, [dialogConfig.openDialog, dialogConfig.openSnackbar]);

  const {
    navigate,
    params: {boardId, search, highlightMode = 'none'},
    change: changeQueryParams,
  } = useQueryMap();
  const [zoomLevel, setZoomLevel] = useState(1);
  const fitDiagram = () => {};

  const onNodeSelect = useCallback(
    (node: any) => {
      const nodeMet = node.data('metadata');
      setValues({
        rightSidebarName: SidebarNames.NodeMetadata,
        nodeMetadataSettings: {
          type: node.data('type'),
          metadata: nodeMet,
          link: node.data('link'),
        },
      });
    },
    [setValues],
  );

  const onEdgeSelect = useCallback(
    (edge: any) => {
      const edgeMeta = edge.data('metadata');
      setValues({
        rightSidebarName: SidebarNames.NodeMetadata,
        nodeMetadataSettings: {
          type: GroupType.EDGE,
          link: edge.data('link'),
          metadata: [
            ...edgeMeta,
            {
              name: 'TITLE',
              value: `Edge connection`,
              type: 'text',
            },
          ],
        },
      });
    },
    [setValues],
  );

  const diagramsViewer = useMemo(() => {
    return (
      <BoardViewer
        search={search}
        id={boardId}
        zoomLevel={zoomLevel}
        highlightMode={highlightMode}
        onZoomChange={zoom => {
          setZoomLevel(zoom);
        }}
        onNodeSelect={onNodeSelect}
        onEdgeSelect={onEdgeSelect}
      />
    );
  }, [boardId, highlightMode, onEdgeSelect, onNodeSelect, search, zoomLevel]);
  return (
    <div className="board-layout">
      {dialog}
      <div className="top-bar">
        <div className="left bar">
          <button
            type="button"
            className="logo-button rounded-md p-1"
            onClick={() => {
              navigate('/');
            }}>
            <img
              src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
              alt="logo"
            />
          </button>
          <select
            onChange={e => changeQueryParams({highlightMode: e.target.value})}>
            <option value="none">None</option>
            <option value="network">Network</option>
          </select>
        </div>
        <div className="center">
          <Input
            label=""
            type="text"
            value={search}
            onChange={e => changeQueryParams({search: e.target.value})}
            autoComplete="user"
            required
            icon={
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            }
          />
        </div>
        <div className="right bar">
          <User />
        </div>
      </div>
      <main>{diagramsViewer}</main>
      {config.rightSidebarName ? (
        <MetadataSidebar
          link={config.nodeMetadataSettings?.link ?? ''}
          type={config.nodeMetadataSettings?.type}
          objects={config.nodeMetadataSettings?.metadata ?? []}
          onCloseSideBar={() =>
            setValues({
              nodeMetadataSettings: undefined,
              rightSidebarName: undefined,
            })
          }
        />
      ) : null}

      <BoardActions
        onFit={fitDiagram}
        zoom={zoomLevel}
        onDecrease={zoom => {
          if (zoom > MIN_ZOOM) {
            setZoomLevel(zoom - 0.1);
          }
        }}
        onIncrease={zoom => {
          if (zoom < MAX_ZOOM) {
            setZoomLevel(zoom + 0.1);
          }
        }}
      />
    </div>
  );
};
