import React, {useMemo, useState} from 'react';
import './style.css';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {MetadataField} from './MetadataField/MetadataField';
import {
  GroupType,
  MetadataItem,
  MetadataPanelNames,
} from '../../../utils/types';
import {resourceTypeImages} from '../../../utils/images';
import {AwsButton} from '../../Buttons/AwsButton';

export const MetadataSidebar: React.FC<{
  type?: GroupType;
  link: string;
  objects: MetadataItem[];
  onCloseSideBar: () => void;
}> = props => {
  const {objects, type, link, onCloseSideBar} = props;
  const imageSrc = type ? resourceTypeImages[type] : null;
  const fields = useMemo(() => {
    return objects;
  }, [objects]);
  const [currentPanel, setCurrentPanel] = useState<MetadataPanelNames>(
    MetadataPanelNames.ATTRIBUTES,
  );

  const panelNames = MetadataPanelNames;

  const seenNames = new Map();
  const filteredFields = fields.filter(field => {
    const isDuplicate = seenNames.has(field.name);
    seenNames.set(field.name, true);
    return !isDuplicate;
  });

  const fieldsComponents = useMemo(() => {
    const result = filteredFields
      .filter(object => object.panelName === currentPanel)
      .map(object => {
        return <MetadataField key={object.name} {...object} />;
      });
    if (result.length === 0) {
      return <div className="text-center">No options found</div>;
    }
    return result;
  }, [currentPanel, filteredFields]);

  return (
    <div className="sidebar right metadata-sidebar">
      <div className="metadata-sidebar-title">
        {imageSrc ? (
          <img className="h-8 w-auto" src={imageSrc} alt=".io" />
        ) : null}
        {link.length > 0 ? (
          <AwsButton
            text="Inspect on AWS"
            type="button"
            onClick={() => window.open(link, '_blank')}
          />
        ) : null}
        <XMarkIcon onClick={onCloseSideBar} width={20} height={20} />
      </div>
      <div className="metadata-sidebar-content mt-4">
        <div className="tab-menu flex flex-row justify-between">
          {Object.keys(panelNames).map(panelName => {
            return (
              <button
                type="button"
                className={`tab-item ${
                  currentPanel ===
                  panelNames[panelName as keyof typeof panelNames]
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  setCurrentPanel(
                    panelNames[
                      panelName as keyof typeof panelNames
                    ] as MetadataPanelNames,
                  )
                }
                key={panelName}>
                <h3>{panelNames[panelName as keyof typeof panelNames]}</h3>
              </button>
            );
          })}
        </div>

        <div className="mt-5">{fieldsComponents}</div>
      </div>
    </div>
  );
};
