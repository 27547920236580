import {Outlet} from 'react-router';
import React, {useMemo} from 'react';
import Sidebar from '../Sidebar';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {Snackbar} from '../Snackbar/Snackbar';

const Layout: React.FC<{}> = props => {
  const {config} = useDialogConfig();

  const dialog = useMemo(() => {
    if (config.openDialog) {
      return config.openDialog;
    }
    if (config.openSnackbar) {
      return <Snackbar {...config.openSnackbar} />;
    }
    return null;
  }, [config.openDialog, config.openSnackbar]);

  return (
    <Sidebar>
      {dialog}
      <Outlet />
    </Sidebar>
  );
};

export default Layout;
