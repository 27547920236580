import React, {
  ReactNode,
  Fragment,
  useState,
  useEffect,
  SVGProps,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  FolderIcon,
  HomeIcon,
  WindowIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import {Link, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {setCurrentRoute} from '../../store/navigationSlice';
import {UserService} from '../../services/user-service';
import Input from '../InputButton';
import {useQueryMap} from '../../hooks/useQueryParams';
import {classNames} from '../../utils/classnames';
import {User} from '../User/User';

interface SidebarProps {
  children: ReactNode;
}

type RouteItem = {
  name: string;
  href: string;
  iconName: keyof typeof icons;
  current: boolean;
  roles: string[];
};

const userNavigation = [
  {
    name: 'My Plan',
    href: '#',
    onClick: () => {
      UserService.goToBillingPage();
    },
  },
  {
    name: 'Sign out',
    href: '#',
    onClick: () => {
      localStorage.clear();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
  },
];
const icons: {
  [key: string]: ForwardRefExoticComponent<
    SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>
  >;
} = {
  HomeIcon,
  FolderIcon,
  WindowIcon,
};

const Sidebar: React.FC<SidebarProps> = ({children}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const routes = useSelector((state: any) => state.navigation.routes);
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    params: {search},
    change,
  } = useQueryMap();
  useEffect(() => {
    dispatch(setCurrentRoute(location.pathname));
  }, [location.pathname, dispatch]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
              alt="Inframap.io"
            />
            <h1 className="pl-4 text-2xl">InfraMap</h1>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="flex flex-1 flex-col gap-y-3">
                      {routes.map((item: any) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-green-600'
                                : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                              'group flex items-center gap-x-3 rounded-md p-2 text-sm font-medium',
                            )}>
                            {React.createElement(icons[item.iconName], {
                              className: classNames(
                                item.current
                                  ? 'text-green-600'
                                  : 'text-gray-400 group-hover:text-green-600',
                                'h-6 w-6 flex-shrink-0',
                              ),
                              'aria-hidden': 'true',
                            })}
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4  bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <Input
            label=""
            type="text"
            value={search}
            onChange={e => change({search: e.target.value})}
            autoComplete="user"
            required
            icon={
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            }
          />

          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
            <User />
          </div>
        </div>

        <main className="py-10 sm:gap-x-6 sm:px-6 lg:px-8">{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
