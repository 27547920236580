import React from 'react';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {BaseButton} from './BaseButton';

interface NextButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const NextButton: React.FC<NextButtonProps> = ({onClick, disabled}) => {
  return (
    <BaseButton
      text="Next"
      rightIcon={<ChevronRightIcon className="mt-1" width={20} height={20} />}
      onClick={onClick}
      type="button"
    />
  );
};

export default NextButton;
