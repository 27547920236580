import {createBrowserRouter} from 'react-router-dom';
import Layout from './components/Layout';
import {AuthResolver} from './resolvers/auth-resolver';
import AuthLayout from './components/AuthLayout/AuthLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Accounts from './pages/Accounts';
import {BoardLayout} from './components/BoardLayout/BoardLayout';
import ForgotPassword from './pages/ForgotPassword';
import IdpFallback from './pages/IdpFallback/IdpFallback';
import EmailFallback from './pages/EmailFallback/EmailFallback';
import {Boards} from './pages/Boards/Boards';
import ResetPassword from './pages/ResetPassword/ResetPassword';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthResolver />,
    children: [
      {
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'signup',
            element: <Signup />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: 'email-fallback',
            element: <EmailFallback />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
          {
            path: 'idp-fallback',
            element: <IdpFallback />,
          },
        ],
      },
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: '/',
            element: <Boards />,
          },
          {
            path: '/accounts',
            element: <Accounts />,
          },
        ],
      },
      {
        path: '/board',
        element: <BoardLayout />,
      },
    ],
  },
]);
