import {GroupType} from './types';

export const resourceTypeImages: Record<GroupType, string> = {
  lambda: './diagram/lambda.svg',
  dynamodb: './diagram/dynamodb.svg',
  autoscaling: './diagram/autoscaling.svg',
  servicediscovery: './diagram/cloudmap.svg',
  'autoscaling-plans': './diagram/autoscaling.svg',
  kms: './diagram/kms.svg',
  route53: './diagram/route53.svg',
  'ecr-public': './diagram/ecr.svg',
  sns: './diagram/sns.svg',
  'codestar-notifications': './diagram/code-star.svg',
  ssm: './diagram/ssm.svg',
  codecommit: './diagram/codecommit.svg',
  ssmmessages: './diagram/ssm.svg',
  sts: './diagram/sts.jpg',
  s3: './diagram/s3.png',
  eks: './diagram/eks.svg',
  ecr: './diagram/ecr.svg',
  ecs: './diagram/ecs.svg',
  subnet: './diagram/subnet.svg',
  vpc: './diagram/vpc.svg',
  ec2: './diagram/ec2.svg',
  secretsmanager: './diagram/secrets-manager.svg',
  logs: './diagram/cloudwatch.svg',
  cloudwatch: './diagram/cloudwatch.svg',
  elasticloadbalancing: './diagram/elasticloadbalancing.svg',
  'cognito-idp': './diagram/cognito.svg',
  ec2messages: './diagram/ec2.svg',
  'codeguru-reviewer': './diagram/code-guru.svg',
  [GroupType.ALL]: '',
  [GroupType.EDGE]: '',
  [GroupType.IAM]: './diagram/iam.svg',
  [GroupType.SES]: './diagram/ses.svg',
};
