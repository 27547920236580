import React, {Fragment} from 'react';
import {
  ArchiveBoxXMarkIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';
import {Menu, Transition} from '@headlessui/react';
import {AccountInfo} from '../../../utils/types';
import './style.css';
import {useDialogConfig} from '../../../contexts/DialogConfigProvider';
import {DeleteAccountDialog} from '../../../dialogs/DeleteAccountDialog/DeleteAccountDialog';

export const Account: React.FC<{
  account: AccountInfo;
  onDelete: () => Promise<void>;
}> = ({account: {accountId, accountName}, onDelete}) => {
  const {config, setValues} = useDialogConfig();
  return (
    <div className="flex flex-row justify-between account-item">
      <h2>{accountId}</h2>
      <h2>{accountName}</h2>
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="hidden lg:flex lg:items-center">
            <EllipsisHorizontalIcon width={40} height={40} />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {/* <Menu.Item>
              {({active}) => (
                <button
                  type="button"
                  className={`${active ? 'bg-gray-100' : ''} w-full text-left`}>
                  <div className="flex flex-row">
                    <PencilIcon className="mt-1" width={20} height={20} />
                    <span className="ml-3">Edit</span>
                  </div>
                </button>
              )}
              </Menu.Item> */}
            <Menu.Item>
              {({active}) => (
                <button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setValues({
                      openDialog: <DeleteAccountDialog onSubmit={onDelete} />,
                    });
                  }}
                  type="button"
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } w-full text-left flex flex-row`}>
                  <ArchiveBoxXMarkIcon
                    className="mt-1"
                    width={20}
                    height={20}
                  />
                  <span className="ml-3">Delete</span>
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
