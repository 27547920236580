import {ChevronLeftIcon} from '@heroicons/react/24/outline';
import React from 'react';
import {CancelButton} from './CancelButton';

interface BackButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const BackButton: React.FC<BackButtonProps> = ({onClick}) => {
  return (
    <CancelButton
      type="button"
      text="Back"
      onClick={onClick}
      icon={<ChevronLeftIcon width={20} height={20} />}
    />
  );
};

export default BackButton;
