import {useEffect, useState} from 'react';
import {DynamoResultDataTypeMap, getPersonalData} from '../utils/aws-services';

export const useUserData = <T extends keyof DynamoResultDataTypeMap>(
  dataType: T,
  id?: string,
) => {
  const [loading, setLoading] = useState(false);
  const [data = [], setData] = useState<
    DynamoResultDataTypeMap[T][] | undefined
  >(undefined);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getPersonalData(dataType, id);
      setData(result);
      setLoading(false);
    };
    fetchData();
  }, [dataType, id]);

  return {loading, data};
};
