import React, {useCallback, useEffect, useState} from 'react';
import {ConfirmSignUpCommand} from '@aws-sdk/client-cognito-identity-provider';
import {useNavigate} from 'react-router';
import {cognito} from '../../utils/authentication';
import {APP_CONFIG} from '../../config/config-builder';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import {useQueryMap} from '../../hooks/useQueryParams';

const EmailFallback: React.FC = () => {
  const [showWarning, setShowWarning] = useState('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);
  const {
    params: {code, user},
  } = useQueryMap();
  const navigate = useNavigate();

  const confirmEmail = useCallback(async () => {
    try {
      await cognito.send(
        new ConfirmSignUpCommand({
          ClientId: APP_CONFIG.awsConfig.cognitoAppClientId,
          Username: user,
          ConfirmationCode: code,
        }),
      );
      setSuccessMessage(['Confirm email success!']);
      setTimeout(() => {
        navigate('/auth/login');
      }, 3 * 1000);
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  }, [code, navigate, user]);

  useEffect(() => {
    if (code && user) {
      confirmEmail();
    }
  }, [code, confirmEmail, user]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        {showWarning && (
          <div className="pt-4">
            <ErrorMessage
              onDismiss={() => setShowWarning('')}
              errors={errorMessage}
            />
          </div>
        )}
        {successMessage.length > 0 && (
          <div className="pt-4">
            <SuccessMessage
              onDismiss={() => setSuccessMessage([])}
              messages={successMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailFallback;
