/* eslint-disable no-restricted-globals */
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import {useAccounts} from '../../hooks/useAccounts';
import {AccountInfo} from '../../utils/types';

export const AccountSelect: React.FC<{
  onSelect: (account: AccountInfo) => void;
}> = ({onSelect}) => {
  const {accounts, refresh, loading} = useAccounts();
  return (
    <div className="accounts-list">
      <div className="flex flex-row justify-end">
        <button
          className={loading ? 'spinner' : ''}
          type="button"
          onClick={() => refresh()}>
          <ArrowPathIcon width={20} height={20} />
        </button>
      </div>
      <div className="flex flex-col">
        {accounts.length > 0 || loading ? (
          accounts.map(account => (
            <button
              className="hover:text-green-500 text-left"
              key={account.accountId}
              type="button"
              onClick={() => onSelect(account)}>
              {`${account.accountName} (${account.accountId})`}
            </button>
          ))
        ) : (
          <div>
            No accounts found,{' '}
            <button
              type="button"
              onClick={() =>
                window.open(`${location.origin}/accounts`, '_blank')
              }>
              Go to Accounts
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
