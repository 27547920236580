import {MinusIcon, PlusIcon} from '@heroicons/react/24/outline';
import './style.css';
import React, {useMemo} from 'react';
import {MAX_ZOOM, MIN_ZOOM} from '../../BoardViewer';

export const BoardActions: React.FC<{
  zoom: number;
  onDecrease: (zoom: number) => void;
  onIncrease: (zoom: number) => void;
  onFit: () => void;
}> = ({zoom, onDecrease, onFit, onIncrease}) => {
  const percentage = useMemo(() => {
    const result = Math.round(
      ((zoom - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)) * 100,
    );
    if (result < 0) return 0;
    if (result > 100) return 100;
    return result;
  }, [zoom]);
  return (
    <div className="board-actions">
      <button type="button" onClick={() => onDecrease(zoom)}>
        <MinusIcon width={30} height={30} />
      </button>
      <h4>{percentage}%</h4>
      <button type="button" onClick={() => onIncrease(zoom)}>
        <PlusIcon width={30} height={30} />
      </button>
    </div>
  );
};
