import React from 'react';
import {DialogLayout} from '../DialogLayout/DialogLayout';

export const DeleteAccountDialog: React.FC<{
  onSubmit: () => Promise<void>;
}> = ({onSubmit}) => {
  return (
    <DialogLayout
      title="Delete Account"
      submitButton="Delete"
      cancelButton="Cancel"
      onSubmit={async (e: any) => {
        e.preventDefault();
        await onSubmit();
      }}>
      <h2>Are you sure that you want delete this account from InfraMap?</h2>
    </DialogLayout>
  );
};
