import React, {useEffect, useState} from 'react';
import {EnvelopeIcon} from '@heroicons/react/20/solid';
import {useSelector} from 'react-redux';
import {ForgotPasswordCommand} from '@aws-sdk/client-cognito-identity-provider';
import {cognito} from '../../utils/authentication';
import Input from '../../components/InputButton';
import {APP_CONFIG} from '../../config/config-builder';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import {BaseButton} from '../../components/Buttons/BaseButton';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [showWarning, setShowWarning] = useState('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);
  const userObj = useSelector((state: any) => state.user);

  const sendForgotPasswordRequest = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    try {
      await cognito.send(
        new ForgotPasswordCommand({
          ClientId: APP_CONFIG.awsConfig.cognitoAppClientId,
          Username: email,
        }),
      );
      setSuccessMessage(['Please check your email for a reset link.']);
      localStorage.setItem('email', email);
    } catch (err) {
      const errorMsg = err!.toString().split(':');
      setErrorMessage([errorMsg[errorMsg.length - 1]]);
      setShowWarning('Recovery failed. Please try again.');
    }
  };

  // useEffect to check if user email is already known from userSlice
  useEffect(() => {
    if (userObj.user) {
      setEmail(userObj.user.email);
      if (userObj.confirmEmailWarning) {
        setShowWarning('Please confirm your email address.');
        setErrorMessage(['Please confirm your email address.']);
      }
    }
  }, [userObj]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Recover your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={sendForgotPasswordRequest}>
          <div>
            <Input
              label="Email"
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
              required
              icon={
                <EnvelopeIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div>
            <BaseButton
              type="submit"
              text="Send recovery email"
              className="w-full"
            />
          </div>
        </form>
        {showWarning && (
          <div className="pt-4">
            <ErrorMessage
              onDismiss={() => setShowWarning('')}
              errors={errorMessage}
            />
          </div>
        )}
        {successMessage.length > 0 && (
          <div className="pt-4">
            <SuccessMessage
              onDismiss={() => setSuccessMessage([])}
              messages={successMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
