import React, {useMemo} from 'react';
import {ContextMenuDialogProps} from './types';
// eslint-disable-next-line import/no-cycle
import {MenuItem} from './MenuItem';
import './style.css';

export const ContextMenuDialog: React.FC<ContextMenuDialogProps> = ({
  style,
  items,
}) => {
  const itemsButton = useMemo(() => {
    if (Array.isArray(items)) {
      return items.map(item => {
        return <MenuItem key={item.label} {...item} />;
      });
    }
    return items;
  }, [items]);
  return (
    <div
      className="context-menu-dialog"
      style={{
        position: 'absolute',
        top: style?.top || '0px',
        left: style?.left || '0px',
        zIndex: 1000,
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        padding: '10px',
        ...style,
      }}>
      <div className="items">{itemsButton}</div>
    </div>
  );
};
