import React, {useEffect, useState} from 'react';
import {SnackbarProps} from './types';
import './style.css';

export const Snackbar: React.FC<SnackbarProps> = ({message, type, onClose}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return open ? (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex justify-center mb-4">
      <div
        className={`snack-bar ${type} bg-gray-800 text-white text-sm font-bold px-4 py-3 rounded`}>
        {message}
      </div>
    </div>
  ) : null;
};
