// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import './index.css';
import {store, persistor} from './store/store';
import {SidebarsConfigProvider} from './contexts/SidebarsConfigProvider';
import {router} from './router';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';
import {DialogConfigProvider} from './contexts/DialogConfigProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SidebarsConfigProvider>
            <DialogConfigProvider>
              <RouterProvider router={router} />
            </DialogConfigProvider>
          </SidebarsConfigProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);

reportWebVitals();
