import {useEffect, useState} from 'react';
import {DialogLayout} from '../DialogLayout/DialogLayout';
import AccountWizard from '../../pages/Accounts/AccountWizard/AccountWizard';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';

export const NewAccountDialog: React.FC<{
  onSubmit: () => Promise<void>;
}> = ({onSubmit}) => {
  const {setValues} = useDialogConfig();
  return (
    <DialogLayout
      title="Account Wizard"
      cancelButton="Cancel"
      width="max-w-2xl">
      <div className="h-[750px] overflow-auto overflow-y-hidden overflow-x-hidden">
        <AccountWizard
          onSubmitAccount={() => {
            setValues({
              openDialog: undefined,
            });
            onSubmit();
          }}
        />
      </div>
    </DialogLayout>
  );
};
