import React, {useState} from 'react';
import {TemplatePreviewProps} from './types';

export const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  templateText,
}) => {
  return (
    <div className="mb-5 flex flex-col items-center px-2 sm:px-0">
      <h3 className="text-lg font-bold mb-2 p-3 text-center">Preview</h3>
      <pre className="bg-gray-100 p-3 rounded-md w-full sm:w-4/5 overflow-auto text-xs sm:text-sm max-h-[490px]">
        {templateText}
      </pre>
    </div>
  );
};
