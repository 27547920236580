import {ElementDefinition} from 'cytoscape';
import {useCallback, useEffect, useState} from 'react';
import {GetObjectCommand} from '@aws-sdk/client-s3';
import {PersonalDataType, getPersonalData, s3} from '../utils/aws-services';
import {APP_CONFIG} from '../config/config-builder';

export const useBoard = (boardId: string) => {
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState<ElementDefinition[]>([]);
  const refresh = useCallback(() => {
    setLoading(true);
    getPersonalData(PersonalDataType.BOARD, boardId).then(async boards => {
      try {
        if (boards[0]) {
          const r = await s3.send(
            new GetObjectCommand({
              Bucket: APP_CONFIG.awsConfig.modelsStore,
              Key: boards[0].s3Key,
            }),
          );
          const body = await r.Body?.transformToString();
          if (body) {
            setElements(JSON.parse(body));
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    });
  }, [boardId]);

  useEffect(() => {
    if (boardId) {
      refresh();
    }
  }, [boardId, refresh]);
  return {loading, elements, refresh: () => refresh()};
};
