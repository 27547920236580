import React from 'react';
import {XCircleIcon} from '@heroicons/react/20/solid';
import {CheckIcon} from '@heroicons/react/24/outline';

interface SuccessMessageProps {
  messages: string[];
  color?: string;
  onDismiss?: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  messages,
  color,
  onDismiss,
}) => {
  if (messages.length === 0) return null;

  return (
    <div
      style={{
        backgroundColor: color || 'rgba(200, 255, 200)', // bg-red-50
      }}
      className="rounded-md p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          {onDismiss && (
            <CheckIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              aria-hidden="true"
              onClick={onDismiss}
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            {/* {errors.length} {errors.length === 1 ? 'error' : 'errors'} with your */}
            Operation successful
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {messages.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
