import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  ArrowTopRightOnSquareIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import './style.css';
import {MetadataItem} from '../../../../utils/types';
import ResourceErrorWidget from '../ResourceError/ResourceErrorWidget';
import {Tooltip} from '../../../Tooltip/Tooltip';

export const MetadataField: React.FC<MetadataItem> = props => {
  const {type, name, value, link} = props;
  if (type === 'text') {
    return (
      <div className="flex flex-row justify-between text-field">
        <h4>
          {name}
          {link ? (
            <ArrowTopRightOnSquareIcon
              width={20}
              height={20}
              onClick={() => window.open(link, '_blank')}
            />
          ) : null}
        </h4>
        <Tooltip text={value}>
          <p>{value}</p>
        </Tooltip>
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(value);
          }}>
          <ClipboardDocumentIcon width={20} height={20} />
        </button>
      </div>
    );
  }
  if (type === 'textarea') {
    return (
      <div>
        <h4>{name}</h4>
        <textarea>{value}</textarea>
      </div>
    );
  }
  if (type === 'button') {
    return (
      <a
        href={value}
        target="_blank"
        className="resource-button"
        rel="noreferrer">
        {name}
        <ArrowTopRightOnSquareIcon className="link" width={20} height={20} />
      </a>
    );
  }
  if (type === 'error') {
    return <ResourceErrorWidget errors={value} />;
  }
  if (type === 'warning') {
    return <ResourceErrorWidget errors={value} />;
  }
  if (type === 'select') {
    return (
      <div>
        <h1>{name}</h1>
        <select>
          {value.map(v => (
            <option key={v.name}>{v.value}</option>
          ))}
        </select>
      </div>
    );
  }
  return (
    <div>
      <div className="attribute-name">
        <h4>{name}</h4>
        {link ? (
          <ArrowTopRightOnSquareIcon
            className="link"
            width={20}
            height={20}
            onClick={() => window.open(link, '_blank')}
          />
        ) : null}
      </div>

      <SyntaxHighlighter launguage="json" style={docco}>
        {value}
      </SyntaxHighlighter>
    </div>
  );
};
