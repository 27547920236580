import React, {ChangeEvent, ReactElement} from 'react';

interface InputProps {
  label: string;
  type: 'text' | 'email' | 'password';
  placeholder?: string;
  icon?: ReactElement;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  required?: boolean;
  forgotPasswordAction?: () => void;
}

const Input: React.FC<InputProps> = ({
  label,
  type,
  placeholder,
  icon,
  value,
  onChange,
  autoComplete,
  required,
  forgotPasswordAction,
}) => {
  return (
    <div className="relative">
      <div className="flex justify-between items-center">
        <label
          htmlFor={label.toLowerCase()}
          className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        {type === 'password' && forgotPasswordAction && (
          <button
            type="button"
            className="text-sm font-semibold text-green-600 hover:text-green-500"
            onClick={forgotPasswordAction}>
            Forgot password?
          </button>
        )}
      </div>
      <div className="mt-2 relative rounded-md shadow-sm">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )}
        <input
          type={type}
          id={label.toLowerCase()}
          name={label.toLowerCase()}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          required={required}
          className={`block w-full rounded-md border-0 py-1.5 ${
            icon ? 'pl-10' : 'pl-3'
          } text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 input-bg-white`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Input;
