export interface AccountInfo {
  accountId: string;
  accountName: string;
}

export enum GroupType {
  ALL = 'all',
  LAMBDA = 'lambda',
  DYNAMODB = 'dynamodb',
  CLOUDWATCH = 'logs',
  CLOUDWATCH_EVENTS = 'cloudwatch',
  ECR_PUBLIC = 'ecr-public',
  SSM = 'ssm',
  ROUTE_53 = 'route53',
  CODE_COMMIT = 'codecommit',
  SERVICE_DISCOVERY = 'servicediscovery',
  COGNITO_IDP = 'cognito-idp',
  CODEGURU = 'codeguru-reviewer',
  CODESTAR = 'codestar-notifications',
  SSMMESSAGES = 'ssmmessages',
  ECS = 'ecs',
  VPC = 'vpc',
  SUBNET = 'subnet',
  EC2MESSAGES = 'ec2messages',
  SNS = 'sns',
  KMS = 'kms',
  STS = 'sts',
  SES = 'ses',
  ELASTICLOADBALANCING = 'elasticloadbalancing',
  EKS = 'eks',
  EC2 = 'ec2',
  ECR = 'ecr',
  IAM = 'iam',
  AUTOSCALING = 'autoscaling',
  AUTOSCALING_PLANS = 'autoscaling-plans',
  S3 = 's3',
  SECRETS_MANAGER = 'secretsmanager',
  EDGE = 'edge',
}

export interface BoardModel {
  name: string;
  id: string;
  s3Key: string;
}

export interface UserData {
  email: string;
  picture: string;
  sub: string;
}

export enum MetadataPanelNames {
  ATTRIBUTES = 'Attributes',
  WARNINGS = 'Warnings',
  ERRORS = 'Errors',
  SECURITY = 'Security',
}

export type MetadataItem =
  | {
      type: 'text';
      name: string;
      panelName: MetadataPanelNames;
      value: string;
      link?: string;
    }
  | {
      type: 'button';
      name: string;
      panelName: MetadataPanelNames;
      value: string;
      link?: string;
    }
  | {
      type: 'textarea' | 'policy' | 'edge';
      name: string;
      panelName: MetadataPanelNames;
      value: string;
      link?: string;
    }
  | {
      type: 'select';
      name: string;
      panelName: MetadataPanelNames;
      link?: string;
      value: {name: string; value: any}[];
    }
  | {
      type: 'policy';
      name: string;
      panelName: MetadataPanelNames;
      value: string;
      link?: string;
    }
  | {
      type: 'node';
      name: string;
      panelName: MetadataPanelNames;
      value: string;
      link?: string;
    }
  | {
      type: 'warning';
      name: string;
      panelName: MetadataPanelNames;
      value: ResourceError[];
      link?: string;
    }
  | {
      type: 'error';
      name: string;
      panelName: MetadataPanelNames;
      value: ResourceError[];
      link?: string;
    };

export interface ResourceError {
  type: 'network' | 'security' | 'configuration';
  message: string;
}

export type HighlightMode = 'none' | 'network';
