import {Outlet} from 'react-router';
import Footer from '../Footer';
import './style.css';

const AuthLayout: React.FC<{}> = props => {
  return (
    <div className="flex flex-col min-h-screen background">
      <Outlet />
      <Footer />
    </div>
  );
};

export default AuthLayout;
