import React, {useState} from 'react';
import {CheckboxProps} from './types';

export const Checkbox: React.FC<CheckboxProps> = ({
  checkboxTitle,
  checkboxDescription,
  checked,
  onChange,
}) => {
  return (
    <div className="flex h-6 items-center">
      <input
        id="checkbox"
        aria-describedby="checkbox-description"
        name="checkbox"
        checked={checked}
        onChange={onChange}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
      <div className="ml-3 text-sm leading-6">
        <label htmlFor="checkbox" className="font-medium text-gray-900">
          {checkboxTitle}
        </label>
        <p id="checkbox-description" className="text-gray-500">
          {checkboxDescription}
        </p>
      </div>
    </div>
  );
};
