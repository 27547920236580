import {useState} from 'react';

export const useLocalStorage = (
  key: string,
  initialValue: string,
): [string, (value: string) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item || initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      setTimeout(() => {
        window.localStorage.setItem(key, value);
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
