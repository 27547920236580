import {useCallback, useEffect, useState} from 'react';
import {PersonalDataType, getPersonalData} from '../utils/aws-services';
import {AccountInfo} from '../utils/types';

export const useAccounts = () => {
  const [accounts, setAccounts] = useState<AccountInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const refresh = useCallback(() => {
    setLoading(true);
    getPersonalData(PersonalDataType.ACCOUNT)
      .then(accountsResult => setAccounts(accountsResult))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    accounts,
    refresh,
    loading,
  };
};
