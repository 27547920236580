import React from 'react';
import {XCircleIcon} from '@heroicons/react/20/solid';

interface MessageProps {
  errors: string[];
  color?: string;
  onDismiss?: () => void;
}

const ErrorMessage: React.FC<MessageProps> = ({errors, color, onDismiss}) => {
  if (errors.length === 0) return null;

  return (
    <div
      style={{
        backgroundColor: color || 'rgba(254, 242, 242)', // bg-red-50
      }}
      className="rounded-md p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          {onDismiss && (
            <XCircleIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              aria-hidden="true"
              onClick={onDismiss}
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {/* {errors.length} {errors.length === 1 ? 'error' : 'errors'} with your */}
            Error on submission
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
