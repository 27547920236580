import {GetObjectCommand} from '@aws-sdk/client-s3';
import {
  PersonalDataType,
  getPersonalData,
  httpRequest,
  s3,
} from '../utils/aws-services';
import {APP_CONFIG} from '../config/config-builder';
import {DiagramGenerationAccountParams, DiagramGenerationParams} from './types';

export namespace InfrastructureViewService {
  export const generateDiagram = async (props: DiagramGenerationParams) => {
    return httpRequest<{s3ObjectKeys: {name: string; key: string}[]}>(
      'diagram-generator',
      'POST',
      {
        target: 'generateRegion',
      },
      props,
      props.handlers,
    );
  };

  export const generateAccount = async (
    props: DiagramGenerationAccountParams,
  ) => {
    return httpRequest<{s3ObjectKeys: {name: string; key: string}[]}>(
      'diagram-generator',
      'POST',
      {
        target: 'generateAccount',
      },
      props,
      props.handlers,
    );
  };

  export const getBoard = async (
    props: DiagramGenerationParams,
    hardRefresh = false,
  ) => {
    const {boardId, region, account} = props;
    const schemas = await getPersonalData(PersonalDataType.BOARD, boardId);
    let s3ObjectKeys: {name: string; key: string}[];
    if (schemas.length === 0 || hardRefresh) {
      const lambdaResult = await generateDiagram(props);
      s3ObjectKeys = lambdaResult.s3ObjectKeys;
    } else {
      s3ObjectKeys = schemas.map(item => ({key: item.s3Key, name: item.name}));
    }

    const graphs = await Promise.all(
      s3ObjectKeys.map(async item => {
        const elementObject = await s3.send(
          new GetObjectCommand({
            Bucket: APP_CONFIG.awsConfig.modelsStore,
            Key: item.key,
          }),
        );
        const elements = JSON.parse(
          (await elementObject.Body?.transformToString()) ?? '{}',
        );
        return {elements, name: item.name};
      }),
    );

    return {graphs};
  };
}
