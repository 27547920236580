/* eslint-disable react/button-has-type */
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import React, {MouseEventHandler, ReactNode} from 'react';

export const AwsButton: React.FC<{
  text: string;
  type: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  rightIcon?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
}> = ({
  text,
  disabled = false,
  loading = false,
  className,
  rightIcon,
  onClick,
  type,
}) => {
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      type={type}
      className={`inline-flex justify-center rounded-md border leading-6 border-transparent ${
        disabled ? 'cursor-not-allowed' : ''
      } ${
        disabled ? 'bg-gray-300' : 'bg-orange-500'
      } px-3 py-1.5 text-sm font-medium ${
        disabled ? 'text-gray-100' : 'text-green-100'
      } hover:bg-orange-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2 ${className}`}>
      <img
        width={25}
        height={25}
        className="mr-1"
        src="/assets/aws.png"
        alt="go to aws"
      />
      {loading ? (
        <ArrowPathIcon width={20} height={20} className="spinner mt-1 mr-1" />
      ) : null}
      {text}
      {rightIcon}
    </button>
  );
};
