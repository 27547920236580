/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, {ReactNode, useState} from 'react';
import './style.css';

export const Tooltip: React.FC<{children: ReactNode; text: string}> = ({
  children,
  text,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-text"
        style={{visibility: show ? 'visible' : 'hidden'}}>
        {text}
      </div>
      <div
        className="children"
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}>
        {children}
      </div>
    </div>
  );
};
