import {useState} from 'react';
import {DialogLayout} from '../DialogLayout/DialogLayout';
import Input from '../../components/InputButton';

export const NewBoardDialog: React.FC<{
  onSubmit: (boardName: string) => Promise<void>;
}> = ({onSubmit}) => {
  const [boardName, setBoardName] = useState('');

  return (
    <DialogLayout
      title="Create new"
      submitButton="Create"
      cancelButton="Cancel"
      onSubmit={async (e: any) => {
        e.preventDefault();
        await onSubmit(boardName);
      }}>
      <Input
        onChange={e => setBoardName(e.target.value)}
        value={boardName}
        label="Board name"
        type="text"
      />
    </DialogLayout>
  );
};
