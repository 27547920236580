import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {DialogConfigContextType} from './types';
import {SnackbarProps} from '../../components/Snackbar/types';

interface DialogConfig {
  openDialog?: JSX.Element;
  openSnackbar?: SnackbarProps;
}

const defaultConfig: DialogConfig = {};

export const DialogConfigContext = createContext<
  DialogConfigContextType<DialogConfig>
>({
  config: defaultConfig,
  setValues: () => {
    throw new Error('SidebarsConfigContext not initialized');
  },
});

export const DialogConfigProvider: React.FC<PropsWithChildren> = props => {
  const [config, setConfig] = useState<DialogConfig>({});

  const setValues = useCallback(
    (
      values:
        | Partial<DialogConfig>
        | ((prevState: DialogConfig) => Partial<DialogConfig>),
    ) => {
      if (typeof values === 'function') {
        setConfig(prev => ({...prev, ...values(prev)}));
      } else {
        setConfig(prev => ({...prev, ...values}));
      }
    },
    [],
  );

  const value = useMemo(() => ({config, setValues}), [config, setValues]);

  return (
    <DialogConfigContext.Provider value={value}>
      {props.children}
    </DialogConfigContext.Provider>
  );
};

export const useDialogConfig = () => useContext(DialogConfigContext);
