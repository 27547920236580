import {XCircleIcon} from '@heroicons/react/24/outline';
import React from 'react';

interface MessageProps {
  errors: string[];
  color?: string;
  onDismiss?: () => void;
}

const WarningBanner: React.FC<MessageProps> = ({errors, color, onDismiss}) => {
  if (errors.length === 0) return null;

  return (
    <div
      style={{
        backgroundColor: color || 'rgb(254 240 138)',
      }}
      className="rounded-md p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          {onDismiss && (
            <XCircleIcon
              className="h-5 w-5 text-yellow-400 cursor-pointer"
              aria-hidden="true"
              onClick={onDismiss}
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {/* {errors.length} {errors.length === 1 ? 'error' : 'errors'} with your */}
            Warning!
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningBanner;
