/* eslint-disable react/button-has-type */
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import React, {MouseEventHandler, ReactNode} from 'react';

export const BaseButton: React.FC<{
  text: string;
  type: 'button' | 'submit';
  icon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  rightIcon?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
}> = ({
  text,
  disabled = false,
  loading = false,
  className,
  rightIcon,
  icon,
  onClick,
  type,
}) => {
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      type={type}
      className={`inline-flex justify-center rounded-md border leading-6 border-transparent ${
        disabled ? 'cursor-not-allowed' : ''
      } ${
        disabled ? 'bg-gray-300' : 'bg-green-500'
      } px-3 py-1.5 text-sm font-medium ${
        disabled ? 'text-gray-100' : 'text-green-100'
      } hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 ${className}`}>
      {icon}
      {loading ? (
        <ArrowPathIcon width={20} height={20} className="spinner mt-1 mr-1" />
      ) : null}
      {text}
      {rightIcon}
    </button>
  );
};
