import React, {Fragment, useMemo, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {
  ArchiveBoxXMarkIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';
import {BoardModel} from '../../../utils/types';
import './style.css';
import {useQueryMap} from '../../../hooks/useQueryParams';
import {useDialogConfig} from '../../../contexts/DialogConfigProvider';
import {DeleteBoardDialog} from '../../../dialogs/DeleteBoardDialog/DeleteBoardDialog';

export const BoardItem: React.FC<{
  board: BoardModel;
  onDelete: () => Promise<void>;
}> = ({board, onDelete}) => {
  const {navigate} = useQueryMap();

  const {config, setValues} = useDialogConfig();
  const menuItems = useMemo(
    () => (
      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        {/* <Menu.Item>
          {({active}) => (
            <button
              type="button"
              className={`${active ? 'bg-gray-100' : ''} w-full text-left`}>
              <div className="flex flex-row">
                <PencilIcon className="mt-1" width={20} height={20} />
                <span className="ml-3">Edit</span>
              </div>
            </button>
          )
          </Menu.Item> */}
        <Menu.Item>
          {({active}) => (
            <button
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setValues({
                  openDialog: <DeleteBoardDialog onSubmit={onDelete} />,
                });
              }}
              className={`${
                active ? 'bg-gray-100' : ''
              } w-full text-left flex flex-row`}>
              <ArchiveBoxXMarkIcon className="mt-1" width={20} height={20} />
              <span className="ml-3">Delete</span>
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    ),
    [onDelete, setValues],
  );
  return (
    <button
      type="button"
      className="board-item justify-between hover:text-green-500 flex flex-row"
      onClick={() => navigate(`/board?boardId=${board.id}`)}>
      <div className="mt-2 board-name">{board.name}</div>
      <Menu as="div" className="relative">
        <Menu.Button
          onClick={e => {
            e.stopPropagation();
          }}
          className="-m-1.5 flex items-center p-1.5">
          <span className="hidden lg:flex lg:items-center">
            <EllipsisHorizontalIcon width={40} height={40} />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          {menuItems}
        </Transition>
      </Menu>
    </button>
  );
};
