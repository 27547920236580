import React, {useCallback, useEffect, useMemo} from 'react';
import {PlusIcon} from '@heroicons/react/24/outline';
import {BoardItem} from './BoardItem/BoardItem';
import {useBoards} from '../../hooks/useBoards';
import './style.css';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {NewBoardDialog} from '../../dialogs/NewBoard/NewBoard';
import {httpRequest} from '../../utils/aws-services';
import {BaseButton} from '../../components/Buttons/BaseButton';
import {useQueryMap} from '../../hooks/useQueryParams';

export const Boards: React.FC<{}> = () => {
  const {elements: boards, loading, refresh} = useBoards();
  const {setValues} = useDialogConfig();
  const {
    params: {search},
  } = useQueryMap();
  const list = useMemo(() => {
    const items = boards.filter(b => !search || b.name.indexOf(search) > -1);
    if (items.length === 0 && !loading) {
      return <h1 className="mt-5 px-2">No boards found</h1>;
    }
    return items.map(board => {
      return (
        <BoardItem
          key={board.id}
          board={board}
          onDelete={async () => {
            await httpRequest('board', 'DELETE', undefined, {id: board.id});
            refresh();
          }}
        />
      );
    });
  }, [boards, loading, refresh, search]);

  const onSubmit = useCallback(
    async (boardName: string) => {
      await httpRequest('board', 'POST', undefined, {name: boardName});
      refresh();
    },
    [refresh],
  );

  const newBoardDialog = useCallback(() => {
    setValues({openDialog: <NewBoardDialog onSubmit={onSubmit} />});
  }, [onSubmit, setValues]);

  return (
    <div className="boards-container">
      <div className="topbar">
        <h1 className="text-4xl">Boards</h1>

        <BaseButton
          className="new-board mt-5"
          onClick={newBoardDialog}
          icon={<PlusIcon style={{width: 25, height: 25}} />}
          text="New Board"
          type="button"
        />
      </div>
      {list}
    </div>
  );
};
