import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';
import {useUserData} from '../../hooks/useUserData';
import {PersonalDataType} from '../../utils/aws-services';
import {UserService} from '../../services/user-service';
import {classNames} from '../../utils/classnames';
import './style.css';

export const User: React.FC<{}> = () => {
  const {data} = useUserData(PersonalDataType.USER_DATA);
  const {email, picture} = (data ?? [])[0] || {email: '', picture: ''};
  const userNavigation = [
    {
      name: 'My Plan',
      href: '#',
      onClick: () => {
        UserService.goToBillingPage();
      },
    },
    {
      name: 'Sign out',
      href: '#',
      onClick: () => {
        localStorage.clear();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      },
    },
  ];
  return (
    <div className="flex items-center gap-x-4 lg:gap-x-6">
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          {email ? (
            <img
              onError={e => {
                if (e.currentTarget) {
                  e.currentTarget.className = 'no-avatar-image';
                  e.currentTarget.style.setProperty(
                    '--avatar-content',
                    `'${email[0].toUpperCase()}'`,
                  );
                }
              }}
              className="h-8 w-8 rounded-full bg-gray-50"
              src={picture || ''}
              alt=""
            />
          ) : null}
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true">
              {email}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {userNavigation.map(item => (
              <Menu.Item key={item.name}>
                {({active}) => (
                  <Link
                    onClick={item.onClick}
                    to={item.href}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900',
                    )}>
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
