import {Stylesheet} from 'cytoscape';
import {GroupType, HighlightMode, ResourceError} from '../../utils/types';
import {resourceTypeImages} from '../../utils/images';

const HIGHTLIGHT_COLOR = '#11AA11';
const HEALTHY_CONNECTION_COLOR = '#11AA11';
const WARNING_CONNECTION_COLOR = '#E9D502';
const ERROR_CONNECTION_COLOR = '#AA1111';

interface DiagramStyleProps {
  highlightMode: HighlightMode;
}

export const getLabel = (node: cytoscape.NodeSingular) => {
  if (node.data('nodeType') !== 'group') {
    const label = node.data('name') || '';
    return label.length > 20 ? `${label.substr(0, 20)}...` : label;
  }
  return node.data('metadata').length;
};

export const diagramStyle: (props: DiagramStyleProps) => Stylesheet[] = ({
  highlightMode,
}) => [
  {
    selector: 'node',
    style: {
      label: 'data(name)',
      'text-valign': 'bottom',
      'text-halign': 'center',
      'font-size': '8px',
      'text-background-color': 'white',
      'text-background-opacity': 1,
      'text-margin-y': 3,
      'text-background-shape': 'rectangle',
      'background-image': function (node) {
        const type = node.data('type') as GroupType;
        const result = resourceTypeImages[type];
        if (result) {
          return result;
        }
        return "url('')";
      },
      shape: 'round-rectangle',
      'border-color': function (node) {
        if (node.data('type') === 'group') {
          return '#dadada';
        }
        const errors = node
          .data('errors')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        const warnings = node
          .data('warnings')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        if (errors && errors.length > 0) {
          return ERROR_CONNECTION_COLOR;
        }
        if (warnings && warnings.length > 0) {
          return WARNING_CONNECTION_COLOR;
        }
        return 'transparent';
      },
      'border-opacity': 0.5,
      'border-width': function (node) {
        if (node.data('type') === 'group') {
          return '1px';
        }
        const errors = node
          .data('errors')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        const warnings = node
          .data('warnings')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        if (errors && errors.length > 0) {
          return '2px';
        }
        if (warnings && warnings.length > 0) {
          return '2px';
        }
        return '0';
      },
      'background-color': function (node) {
        const type = node.data('type');
        if (type === 'all-resources') {
          return 'blue';
        }
        if (type === 'group') {
          return '#fff';
        }
        return 'transparent';
      },
      'background-fit': 'cover',
      content: getLabel,
    },
  },
  {
    selector: 'node.cluster',
    style: {
      width: 100,
      height: 100,
      'background-image': function (node) {
        const type = resourceTypeImages[node.data('clusterType') as GroupType];
        if (type) {
          return `url('${type}')`;
        }
        return "url('')";
      },
      padding: '25px',
      'background-height': '25px',
      'background-width': '25px',
      'background-position-x': '0%',
      'background-position-y': '0%',
      'background-clip': 'none',
      'background-fit': 'none',
      'background-repeat': 'no-repeat',
    },
  },
  {
    selector: 'edge',
    style: {
      'line-color': function (node) {
        const errors = node
          .data('errors')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        const warnings = node
          .data('warnings')
          ?.filter(
            (e: ResourceError) =>
              e.type !== 'network' || highlightMode === 'network',
          );
        if (errors.length > 0) {
          return ERROR_CONNECTION_COLOR;
        }
        if (warnings.length > 0) {
          return WARNING_CONNECTION_COLOR;
        }
        return highlightMode === 'network'
          ? HEALTHY_CONNECTION_COLOR
          : '#9a9a9a';
      },
      opacity: 0.5,
    },
  },
  {
    selector: 'edge:selected',
    style: {
      opacity: 1,
      'line-color': edge => {
        if (highlightMode === 'none') {
          return HIGHTLIGHT_COLOR;
        }
        return edge.style('line-color');
      },
    },
  },
  {
    selector: 'node.highlighted',
    style: {
      'border-opacity': 1,
      'border-width': '4px',
      'border-color': WARNING_CONNECTION_COLOR,
    },
  },
  {
    selector: 'node:selected',
    style: {
      'border-opacity': 1,
      'border-width': node => {
        if (highlightMode === 'none') {
          return '2px';
        }
        return node.style('border-width');
      },
      'border-color': node => {
        if (highlightMode === 'none') {
          return HIGHTLIGHT_COLOR;
        }
        return node.style('border-color');
      },
    },
  },
];
