import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {SidebarsConfig, SidebarsConfigContextType} from './types';

export const SidebarsConfigContext = createContext<SidebarsConfigContextType>({
  config: {
    rightSidebarName: undefined,
  },
  setValues: () => {
    throw new Error('SidebarsConfigContext not initialized');
  },
});

export const SidebarsConfigProvider: React.FC<PropsWithChildren> = props => {
  const [config, setConfig] = useState<SidebarsConfig>({
    rightSidebarName: undefined,
  });

  const setValues = useCallback(
    (
      values:
        | Partial<SidebarsConfig>
        | ((prevState: SidebarsConfig) => Partial<SidebarsConfig>),
    ) => {
      if (typeof values === 'function') {
        setConfig(prev => ({...prev, ...values(prev)}));
      } else {
        setConfig(prev => ({...prev, ...values}));
      }
    },
    [],
  );

  const value = useMemo(() => ({config, setValues}), [config, setValues]);

  return (
    <SidebarsConfigContext.Provider value={value}>
      {props.children}
    </SidebarsConfigContext.Provider>
  );
};

export const useSidebarsConfig = () => useContext(SidebarsConfigContext);
