import {FullAppConfig} from './types';

export const LOCAL_CONFIGURATION: FullAppConfig = {
  name: 'local',
  awsConfig: {
    region: 'eu-west-1',
    congnitoIdentityPoolId: 'eu-west-1:08bbfc78-f0d7-4524-8ac4-3ab83e27de10',
    cognitoUserPoolId: 'eu-west-1_HysbJxAFk',
    apiGatewayUrl: 'https://7jix8riswh.execute-api.eu-west-1.amazonaws.com/api',
    cognitoAppClientId: '6vg2vlah6i4llh7a2gr79s87sq',
    cognitoUserPoolUrl: 'auth.develop.inframap.cloud',
    registryTableName: 'develop-inframap-inframap-registry',
    userOperationsLambda:
      'develop-inframap-stack-de-developinframapUserOpera-157J6lgDkp6O',
    diagramGenerationLambda:
      'develop-inframap-stack-de-developinframapdiagramge-Fovmn9PkXW8I',
    modelsStore: 'develop-inframap-infrastructure-models-bucket',
    accountId: '682060008544',
  },
};
