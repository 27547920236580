import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment, ReactNode, useCallback, useState} from 'react';
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import {BaseDialogConfig} from '../types';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {BaseButton} from '../../components/Buttons/BaseButton';
import {CancelButton} from '../../components/Buttons/CancelButton';
import './style.css';

export const DialogLayout: React.FC<
  {children: ReactNode} & BaseDialogConfig
> = ({
  children,
  title,
  submitButton,
  cancelButton,
  onSubmit,
  width = 'max-w-md',
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {setValues} = useDialogConfig();
  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setValues({openDialog: undefined});
    setIsOpen(false);
  }, [setValues]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={`w-full ${width} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
                <form
                  className="mt-4"
                  onSubmit={e => {
                    if (onSubmit === undefined) return;
                    setLoading(true);
                    onSubmit(e)
                      .then(() => {
                        onClose();
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}>
                  {children}
                  <div className="mt-4 flex flex-row justify-between">
                    {cancelButton ? (
                      <CancelButton
                        onClick={onClose}
                        type="button"
                        text={cancelButton}
                      />
                    ) : null}
                    {submitButton ? (
                      <BaseButton
                        text={submitButton}
                        type="submit"
                        icon={
                          loading ? (
                            <ArrowPathIcon
                              className="spinner"
                              width={20}
                              height={20}
                            />
                          ) : undefined
                        }
                      />
                    ) : null}
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
