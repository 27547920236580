import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {EnvelopeIcon, KeyIcon} from '@heroicons/react/20/solid';
import {useSelector} from 'react-redux';
import {authService} from '../../utils/authentication';
import Input from '../../components/InputButton';
import {APP_CONFIG} from '../../config/config-builder';
import {BaseButton} from '../../components/Buttons/BaseButton';
import WarningBanner from '../../components/WarningBanner';
import './style.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const userObj = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const currentLocation = window.location.origin;
  const [loading, setLoading] = useState(false);

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await authService.login(email, password);
      localStorage.setItem(
        'refresh-token',
        result.AuthenticationResult?.RefreshToken ?? '',
      );
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch (error) {
      console.error('Login failed:', error);
      const errorMsg = error!.toString().split(':');
      setErrorMessage([errorMsg[errorMsg.length - 1]]);
      setShowWarning('Login failed. Please try again.');
    } finally {
      setLoading(false);
    }

    return false;
  };

  // useEffect to check if user email is already known from userSlice
  useEffect(() => {
    if (userObj.user) {
      setEmail(userObj.user.email);
      if (userObj.confirmEmailWarning) {
        setShowWarning(
          'Signup success, please go to you mail inbox and confirm your email address to sign in.',
        );
      }
    }
  }, [userObj]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <BaseButton
          icon={
            <img
              alt="google-icon"
              className="google-icon w-5 mr-2"
              src="/assets/google.png"
            />
          }
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            location.href = `https://${APP_CONFIG.awsConfig.cognitoUserPoolUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${currentLocation}/auth/idp-fallback&response_type=code&client_id=${APP_CONFIG.awsConfig.cognitoAppClientId}&scope=email openid profile`;
          }}
          text="Sign in with Google"
          type="button"
        />
        <div className="or-sign-with mt-5">
          <h3>or use your email to sign in: </h3>
        </div>

        <form className="space-y-6 mt-3" onSubmit={login}>
          <div>
            <Input
              label="Email"
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
              required
              icon={
                <EnvelopeIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div>
            <Input
              label="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              icon={
                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              }
              forgotPasswordAction={() => {
                navigate('/auth/forgot-password');
              }}
            />
          </div>
          <div>
            <BaseButton
              loading={loading}
              text="Sign in"
              type="submit"
              className="w-full"
            />
          </div>
        </form>
        {showWarning && (
          <div className="pt-4">
            <WarningBanner errors={[showWarning]} />
          </div>
        )}
        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <button
            type="button" // Changed from <a> to <button>
            className="font-semibold leading-6 text-green-600 hover:text-green-500"
            onClick={() => {
              navigate('/auth/signup');
            }}>
            Signup
          </button>
        </p>
      </div>
    </div>
  );
};

export default Login;
