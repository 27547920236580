/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './style.css';
import {useLocalStorage} from '../../utils/localstorage';
import {HighlightMode} from '../../utils/types';
import {ContextMenuItem} from '../BoardLayout/ContextMenuDialog/types';
import {regions} from '../../utils/regions';
import {ContextMenuDialog} from '../BoardLayout/ContextMenuDialog/ContextMenuDialog';
import {InfrastructureViewService} from '../../services/infrastructure-view-service';
import {useAccounts} from '../../hooks/useAccounts';
import {diagramStyle, getLabel} from './diagram-style';
import {Loader} from '../Loader/Loader';
import {useBoard} from '../../hooks/useBoard';
import {AccountSelect} from '../AccountSelect/AccountSelect';
import {useDialogConfig} from '../../contexts/DialogConfigProvider';
import {ErrorCodes} from '../../utils/error-codes';
import {SubscriptionDialog} from '../../dialogs/SubscriptionDialog/SubscriptionDialog';

export const MAX_ZOOM = 5;
export const MIN_ZOOM = 0.5;

let dialogOpenedInGraph = false;

function getCenterOfScreen() {
  const element = document.getElementsByTagName('body').item(0);
  if (!element) {
    return {x: 0, y: 0};
  }

  const rect = element.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
}

export const BoardViewer: React.FC<{
  id: string;
  zoomLevel: number;
  search: string;
  highlightMode: HighlightMode;
  onZoomChange: (zoom: number) => void;
  onNodeSelect: (node: any) => void;
  onEdgeSelect: (edge: any) => void;
}> = props => {
  const {
    id: diagramId,
    highlightMode,
    search,
    onZoomChange,
    onNodeSelect,
    onEdgeSelect,
    zoomLevel,
  } = props;
  const {setValues} = useDialogConfig();
  const {elements, refresh, loading: boardLoading} = useBoard(diagramId);
  const {accounts} = useAccounts();
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0});
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>(
    [],
  );
  const regionContextMenu: (account: string) => ContextMenuItem[] = useCallback(
    account => {
      return [
        {
          label: 'New region',
          value: 'new_region',
          onClick: () => {},
          subItems: regions.map(region => {
            return {
              label: `${region.name} (${region.code}`,
              value: region.code,
              onClick: () => {
                setIsLoading(true);
                InfrastructureViewService.generateDiagram({
                  account,
                  region: region.code,
                  boardId: diagramId,
                  handlers: {
                    [ErrorCodes.NO_SUBSCRIPTION]: async e => {
                      console.log('error', e);
                      setValues({
                        openDialog: (
                          <SubscriptionDialog
                            link={e.session}
                            title={e.message}
                          />
                        ),
                      });
                    },
                  },
                })
                  .catch(e => {
                    setValues({
                      openSnackbar: {
                        message: e.message,
                        type: 'error',
                      },
                    });
                  })
                  .finally(() => {
                    refresh();
                    setIsLoading(false);
                  });
              },
            };
          }),
        },
      ];
    },
    [diagramId, refresh, setValues],
  );

  const regionRereshContextMenu: (
    account: string,
    region: string,
  ) => ContextMenuItem[] = useCallback(
    (account, region) => {
      return [
        {
          label: 'Refresh diagram',
          value: 'refresh_region',
          onClick: () => {
            setIsLoading(true);
            InfrastructureViewService.generateDiagram({
              account,
              region,
              boardId: diagramId,
              handlers: {
                [ErrorCodes.NO_SUBSCRIPTION]: async e => {
                  console.log('error', e);
                  setValues({
                    openDialog: (
                      <SubscriptionDialog link={e.session} title={e.message} />
                    ),
                  });
                },
              },
            })
              .catch(e => {
                setValues({
                  openSnackbar: {
                    message: e.message,
                    type: 'error',
                  },
                });
              })
              .finally(() => {
                refresh();
                setIsLoading(false);
              });
          },
        },
      ];
    },
    [diagramId, refresh, setValues],
  );
  const accountContextMenu: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: 'New Account',
        value: 'new_account',
        onClick: () => {},
        subItems: (
          <AccountSelect
            onSelect={account => {
              setIsLoading(true);
              InfrastructureViewService.generateAccount({
                account: account.accountId,
                boardId: diagramId,
                handlers: {
                  [ErrorCodes.NO_SUBSCRIPTION]: async e => {
                    console.log('error', e);
                    setValues({
                      openDialog: (
                        <SubscriptionDialog
                          link={e.session}
                          title={e.message}
                        />
                      ),
                    });
                  },
                },
              })
                .catch(e => {
                  setValues({
                    openSnackbar: {
                      message: e.message,
                      type: 'error',
                    },
                  });
                })
                .finally(() => {
                  refresh();
                  setIsLoading(false);
                });
            }}
          />
        ),
      },
    ];
  }, [diagramId, refresh, setValues]);

  const cyRef = useRef<cytoscape.Core | null>(null);
  const [diagramPositions, setDiagramPositions] = useLocalStorage(
    `diagram-positions-${diagramId}`,
    '{}',
  );

  const handleGraphContext = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      const node = event.target;
      const name = node.data('name');
      const account = accounts.find(r => r.accountId === name);
      const region = regions.find(r => r.code === name);
      if (account) {
        setContextMenuItems(regionContextMenu(account.accountId));
        setContextMenuPosition({
          x: event.renderedPosition.x,
          y: event.renderedPosition.y,
        });
        setContextMenuOpen(true);
      }
      if (region) {
        console.log(node.data());
        setContextMenuItems(
          regionRereshContextMenu(node.data('account'), region.code),
        );
        setContextMenuPosition({
          x: event.renderedPosition.x,
          y: event.renderedPosition.y,
        });
        setContextMenuOpen(true);
      }
    },
    [accounts, regionContextMenu, regionRereshContextMenu],
  );

  const handleWindowContext = useCallback(
    (event: any) => {
      event.preventDefault();
      if (dialogOpenedInGraph) {
        dialogOpenedInGraph = false;
        return;
      }
      setContextMenuPosition({
        x: event.pageX,
        y: event.pageY,
      });
      setContextMenuItems(accountContextMenu as ContextMenuItem[]);
      setContextMenuOpen(true);
    },
    [accountContextMenu],
  );

  const parsedDiagramPositions = useMemo(() => {
    return JSON.parse(diagramPositions as string);
  }, [diagramPositions]);

  const drawGrid = useCallback(
    (pan: cytoscape.Position, zoom: number) => {
      const canvas = document.getElementById(
        `background-grid-${diagramId}`,
      ) as HTMLCanvasElement;
      const container = document.getElementById(
        `diagram-container-${diagramId}`,
      ) as HTMLDivElement;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx || !container) return;
      canvas.style.background = '#f2f2f2';
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const baseGridSize = 30;
      const gridSize = baseGridSize * (zoom * 2);
      if (zoom > 0) {
        for (let x = 0; x <= canvas.width; x += gridSize) {
          ctx.moveTo(x + (pan.x % gridSize), 0);
          ctx.lineTo(x + (pan.x % gridSize), canvas.height);
        }
        for (let y = 0; y <= canvas.height; y += gridSize) {
          ctx.moveTo(0, y + (pan.y % gridSize));
          ctx.lineTo(canvas.width, y + (pan.y % gridSize));
        }

        ctx.strokeStyle = '#e0e0e0';
        ctx.stroke();
      }
    },
    [diagramId],
  );

  useEffect(() => {
    cyRef.current?.nodes().forEach(n => {
      n.removeClass('highlighted');
    });
    if (!search || search.length === 0) return;
    cyRef.current?.nodes().forEach(n => {
      if (n.data('name').toLowerCase().indexOf(search.toLowerCase()) > -1) {
        n.addClass('highlighted');
      }
    });
  }, [search]);

  useEffect(() => {
    cyRef.current?.zoom(zoomLevel);
    const pan = cyRef.current?.pan();
    if (pan) {
      drawGrid(pan, zoomLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomDiagram = useCallback(
    (newZoom: number, cursorX: number, cursorY: number) => {
      const container = document.getElementById(`cy-${diagramId}`);
      const cy = cyRef.current;
      if (!cy || !container) return;
      drawGrid(cy.pan(), newZoom);
      const rect = container.getBoundingClientRect();
      const pos = {
        x: cursorX - rect.left,
        y: cursorY - rect.top,
      };
      const cyBeforePos = {
        x: (pos.x - cy.pan().x) / cy.zoom(),
        y: (pos.y - cy.pan().y) / cy.zoom(),
      };

      const newPan = {
        x: pos.x - cyBeforePos.x * newZoom,
        y: pos.y - cyBeforePos.y * newZoom,
      };

      cy.viewport({
        zoom: newZoom,
        pan: newPan,
      });
    },
    [diagramId, drawGrid],
  );

  useEffect(() => {
    const {x, y} = getCenterOfScreen();
    zoomDiagram(zoomLevel, x, y);
  }, [zoomDiagram, zoomLevel]);

  const handleWheelScrolling = useCallback(
    (event: WheelEvent) => {
      if (event.ctrlKey) {
        const container = document.getElementById(`cy-${diagramId}`);
        event.preventDefault();

        const cy = cyRef.current;
        if (!cy || !container) return;

        const deltaY = event.deltaY;
        const zoomFactor = deltaY > 0 ? 0.85 : 1.15;
        const newZoom = cy.zoom() * zoomFactor;
        if (newZoom < MIN_ZOOM || newZoom > MAX_ZOOM) return;
        zoomDiagram(newZoom, event.clientX, event.clientY);
        onZoomChange(newZoom);
      }
    },
    [diagramId, onZoomChange, zoomDiagram],
  );

  const refreshDiagram = useCallback(() => {
    cytoscape.use(fcose);
    const container = document.getElementById(`cy-${diagramId}`);
    drawGrid(
      cyRef.current ? cyRef.current.pan() : {x: 0, y: 0},
      cyRef.current?.zoom() ?? zoomLevel,
    );
    try {
      cyRef.current = cytoscape({
        container,
        layout: {
          name: 'fcose',
          idealEdgeLength: 150,
          nodeRepulsion(node: any) {
            if (node.classes()[0] === 'cluster') {
              return 100;
            }
            return 4500 * node.degree();
          },
          gravityCompound: 0.1,
          animate: false,
          fit: true,
          gravityRange: 3,
          gravityRangeCompound: 1.8,
          numIter: 2500,
        } as any,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        userZoomingEnabled: false,
        style: diagramStyle({highlightMode}),
        elements,
      });

      if (Object.keys(parsedDiagramPositions).length > 0) {
        cyRef.current?.nodes().forEach(node => {
          const id = node.id();
          if (parsedDiagramPositions[id]) {
            node.position(parsedDiagramPositions[id]);
          }
        });
      }
      cyRef.current?.nodes().forEach(node => {
        const id = node.id();
        parsedDiagramPositions[id] = node.position();
      });
      setDiagramPositions(JSON.stringify(parsedDiagramPositions));

      cyRef.current.on('tap', 'node', evt => {
        const node = evt.target;
        console.log(node.data());
        cyRef.current?.edges().style('opacity', '0.5');
        node.connectedEdges().style('opacity', '1');
        onNodeSelect(evt.target);
      });
      cyRef.current.on('cxttap', 'node', evt => {
        dialogOpenedInGraph = true;
        handleGraphContext(evt);
      });
      cyRef.current.on('tap', 'edge', evt => {
        const edge = evt.target;
        edge.style('opacity', '1');
        onEdgeSelect(edge);
      });
      cyRef.current.on('layoutstop', () => {
        cyRef.current?.fit();
      });
      cyRef.current.on('position', event => {
        const node = event.target;
        parsedDiagramPositions[node.id()] = node.position();

        setDiagramPositions(JSON.stringify(parsedDiagramPositions));
      });
      cyRef.current.on('mouseover', 'node', event => {
        const node = event.target;
        if (node.data('nodeType') !== 'group') {
          node.style('content', node.data('name'));
        }
      });
      cyRef.current.on('pan', event => {
        const position = cyRef.current?.pan();
        const zoom = cyRef.current?.zoom();
        if (position && zoom) {
          drawGrid(position, zoom);
        }
      });
      cyRef.current.on('mouseout', 'node', event => {
        const node = event.target;
        node.style('content', getLabel(node));
      });
      container?.removeEventListener('wheel', handleWheelScrolling);
      container?.addEventListener('wheel', handleWheelScrolling, {
        passive: false,
      });
      cyRef.current.zoom(zoomLevel);
      cyRef.current.center();
    } catch (e) {
      console.error(e);
    }

    return () => {
      container?.removeEventListener('wheel', handleWheelScrolling);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramId, elements]);

  useEffect(() => {
    if (cyRef.current) {
      cyRef.current.style(diagramStyle({highlightMode}));
    }
  }, [highlightMode]);

  useEffect(() => {
    refreshDiagram();
  }, [refreshDiagram]);

  if (!diagramId) return <h1>Loading...</h1>;
  return (
    <div
      className="diagram-container"
      id={`diagram-container-${diagramId}`}
      onContextMenu={handleWindowContext}
      onClick={() => {
        setContextMenuOpen(false);
      }}>
      {loading || boardLoading ? (
        <div className="loading-overlay">
          <Loader message="Generation in progress, please wait..." />
        </div>
      ) : null}
      <canvas
        className="grid"
        id={`background-grid-${diagramId}`}
        style={{position: 'absolute'}}
      />
      {elements.length === 0 && !(loading || boardLoading) ? (
        <div className="absolute w-full text-center">
          <div className="flex items-center h-screen justify-center">
            <img
              width={30}
              height={30}
              className="mt-2"
              src="/assets/input-mouse-click-right.svg"
              alt="mouse"
            />
            <h1>Please click right mouse button to start</h1>
          </div>
        </div>
      ) : null}
      {isContextMenuOpen && (
        <ContextMenuDialog
          items={contextMenuItems}
          style={{
            top: `${contextMenuPosition.y}px`,
            left: `${contextMenuPosition.x}px`,
          }}
        />
      )}
      <div className="cy" id={`cy-${diagramId}`} />
    </div>
  );
};
