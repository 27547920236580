import {useCallback, useEffect, useState} from 'react';
import {PersonalDataType, getPersonalData, s3} from '../utils/aws-services';
import {BoardModel} from '../utils/types';

export const useBoards = () => {
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState<BoardModel[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    getPersonalData(PersonalDataType.BOARD)
      .then(async boards => {
        setElements(boards);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {loading, elements, refresh: () => refresh()};
};
