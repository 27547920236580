import {DEVELOP_CONFIGURATION} from './dev.config';
import {LOCAL_CONFIGURATION} from './local.config';
import {PRODUCTION_CONFIGURATION} from './prod.config';
import {AppConfiguration, FullAppConfig} from './types';

const ALL_ENVS: {[c: string]: AppConfiguration} = {
  develop: DEVELOP_CONFIGURATION,
  local: LOCAL_CONFIGURATION,
};

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'develop';

const generateConfig = (): FullAppConfig => {
  const envConfig = ALL_ENVS[APP_ENVIRONMENT] || {};
  return {
    ...PRODUCTION_CONFIGURATION,
    ...envConfig,
  };
};

export const APP_CONFIG = generateConfig();

console.debug('Initialize app with config:', APP_CONFIG);
