import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {authService} from '../../utils/authentication';
import {useQueryMap} from '../../hooks/useQueryParams';

const IdpFallback: React.FC = () => {
  const [showWarning, setShowWarning] = useState('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const {
    params: {code},
  } = useQueryMap();
  const navigate = useNavigate();

  const loginWithIdp = useCallback(async () => {
    try {
      const result = await authService.loginWithIdpCode(code);
      // eslint-disable-next-line no-restricted-globals
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      const errorMsg = error!.toString().split(':');
      setErrorMessage([errorMsg[errorMsg.length - 1]]);
      setShowWarning('Login failed. Please try again.');
    }

    return false;
  }, [code, navigate]);

  useEffect(() => {
    if (code) {
      loginWithIdp();
    }
  }, [code, loginWithIdp]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Redirecting to dashboard...
        </h2>
      </div>
    </div>
  );
};

export default IdpFallback;
