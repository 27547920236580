import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  user: null as null | any, // Explicitly state that this can be null or string
  error: null as null | any,
  loading: false,
  confirmEmailWarning: false,
  resetPassword: false,
  resetPasswordError: null as null | any,
  resetPasswordLoading: false,
};

const userSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any | null>) => {
      state.user = action.payload;
    },
    setConfirmEmailWarning: (state, action: PayloadAction<boolean>) => {
      state.confirmEmailWarning = action.payload;
    },
  },
});

export const {setUser, setConfirmEmailWarning} = userSlice.actions;

export default userSlice.reducer;
