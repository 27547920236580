import {FullAppConfig} from './types';

export const DEVELOP_CONFIGURATION: FullAppConfig = {
  name: 'develop',
  awsConfig: {
    region: process.env.REACT_APP_REGION ?? '',
    accountId: process.env.REACT_APP_ACCOUNT_ID ?? '',
    congnitoIdentityPoolId:
      process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID ?? '',
    cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    apiGatewayUrl: process.env.REACT_APP_API_GATEWAY_URL ?? '',
    cognitoUserPoolUrl: process.env.REACT_APP_COGNITO_USER_POOL_URL ?? '',
    cognitoAppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID ?? '',
    registryTableName: process.env.REACT_APP_REGISTRY_TABLE_NAME ?? '',
    userOperationsLambda: process.env.REACT_APP_USEROPERATIONS_LAMBDA ?? '',
    diagramGenerationLambda:
      process.env.REACT_APP_DIAGRAMGENERATION_LAMBDA ?? '',
    modelsStore: process.env.REACT_APP_MODELS_STORE ?? '',
  },
};
