import {
  AuthFlowType,
  AuthenticationResultType,
  ChallengeNameType,
  ChangePasswordCommandOutput,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ForgotPasswordCommand,
  ForgotPasswordResponse,
  InitiateAuthCommand,
  InitiateAuthCommandOutput,
  RespondToAuthChallengeCommand,
  SignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import {CognitoIdentityClient} from '@aws-sdk/client-cognito-identity';
import jwtDecode from 'jwt-decode';
import {APP_CONFIG} from '../config/config-builder';

const accountId = APP_CONFIG.awsConfig.accountId;

const cognitoIdentityId = APP_CONFIG.awsConfig.congnitoIdentityPoolId;

export const cognito = new CognitoIdentityProviderClient({
  region: APP_CONFIG.awsConfig.region,
});
export const cognitoIdentity = new CognitoIdentityClient({
  region: APP_CONFIG.awsConfig.region,
});

const cognitoRegion = APP_CONFIG.awsConfig.region;
const cognitoUserPoolId = APP_CONFIG.awsConfig.cognitoUserPoolId;

const cognitoAppClientId = APP_CONFIG.awsConfig.cognitoAppClientId;
const cognitoUrl = `https://${APP_CONFIG.awsConfig.cognitoUserPoolUrl}`;

let iamCredentials: IAMCredentials;

export interface UserDto {
  email: string;
  username: string;
  avatar: string;
  name: string;
  surname: string;
}

export interface OAuthCredentials {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

export interface IAMCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

export const authService = {
  jwtToUserDto: (jwt: string): UserDto => {
    const data = jwtDecode(jwt) as Record<string, string>;
    return {
      email: data.email,
      username: data.sub,
      avatar: data.picture,
      name: data.given_name,
      surname: data.family_name,
    };
  },
  login: async (
    email: string,
    password: string,
  ): Promise<InitiateAuthCommandOutput> => {
    const response = await cognito.send(
      new InitiateAuthCommand({
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        ClientId: cognitoAppClientId,
        AuthParameters: {
          USERNAME: email,
          PASSWORD: password,
        },
      }),
    );
    if (response.ChallengeName) {
      // TODO return a redirect to the FE to a path that identify the challenge (you can get the challenge from  response.ChallengeName)
    } else if (response.AuthenticationResult?.RefreshToken) {
      localStorage.setItem(
        'username',
        authService.jwtToUserDto(response.AuthenticationResult.IdToken!)
          .username,
      );
      await authService.useRefreshToken(
        response.AuthenticationResult?.RefreshToken,
      );
    } else {
      console.debug('No REFRESH TOKEN FOUND');
    }

    return response;
  },
  getOAuthCredentials: async (code: string): Promise<OAuthCredentials> => {
    const body: URLSearchParams = new URLSearchParams({
      client_id: cognitoAppClientId,
      code,
      // eslint-disable-next-line no-restricted-globals
      redirect_uri: `${location.origin}/auth/idp-fallback`,
      grant_type: 'authorization_code',
      scope: 'openid email profile',
    });
    return (
      await fetch(`${cognitoUrl}/oauth2/token`, {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
    ).json();
  },
  loginWithIdpCode: async (code: string) => {
    const response = await authService.getOAuthCredentials(code);
    localStorage.setItem(
      'username',
      authService.jwtToUserDto(response.id_token!).username,
    );
    await authService.useRefreshToken(response.refresh_token);
  },
  recoveryPassword: async (email: string): Promise<ForgotPasswordResponse> => {
    return cognito.send(
      new ForgotPasswordCommand({
        ClientId: cognitoAppClientId,
        Username: email,
      }),
    );
  },
  useRefreshToken: async (
    refreshToken?: string | null,
  ): Promise<{
    oAuthCredentials: AuthenticationResultType | undefined;
    iamCredentials: IAMCredentials;
  }> => {
    if (!refreshToken) {
      refreshToken = localStorage.getItem('refresh-token');
      if (!refreshToken) throw new Error('No Refresh token present');
    } else {
      localStorage.setItem('refresh-token', refreshToken);
    }
    const response = await cognito.send(
      new InitiateAuthCommand({
        AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
        ClientId: cognitoAppClientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      }),
    );
    const logins: {[key: string]: string} = {};
    logins[`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`] =
      response.AuthenticationResult!.IdToken!;
    let identityId = localStorage.getItem('identityId');
    if (!identityId) {
      const result = await (
        await fetch(
          `https://cognito-identity.${APP_CONFIG.awsConfig.region}.amazonaws.com`,
          {
            headers: {
              'Content-Type': 'application/x-amz-json-1.1',
              'X-Amz-Target': 'AWSCognitoIdentityService.GetId',
            },
            method: 'POST',
            body: JSON.stringify({
              AccountId: accountId,
              IdentityPoolId: cognitoIdentityId,
              Logins: logins,
            }),
          },
        )
      ).json();
      identityId = result.IdentityId;
      console.log('identity id', identityId);
      if (identityId) {
        localStorage.setItem('identityId', identityId);
      }
    }

    const credsResponse = await (
      await fetch(
        `https://cognito-identity.${APP_CONFIG.awsConfig.region}.amazonaws.com`,
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target':
              'AWSCognitoIdentityService.GetCredentialsForIdentity',
          },
          method: 'POST',
          body: JSON.stringify({
            IdentityId: identityId,
            Logins: logins,
          }),
        },
      )
    ).json();
    if (!identityId) throw new Error('Cannot find identityId');
    const credentials = credsResponse.Credentials;
    if (credentials?.AccessKeyId && credentials.SecretKey) {
      iamCredentials = {
        accessKeyId: credentials?.AccessKeyId,
        secretAccessKey: credentials?.SecretKey,
        sessionToken: credentials?.SessionToken ?? '',
      };
    } else {
      throw new Error(`Cannot find credentials ${JSON.stringify(credentials)}`);
    }
    localStorage.setItem('identityId', identityId);
    return {
      oAuthCredentials: response.AuthenticationResult,
      iamCredentials: {
        accessKeyId: credentials?.AccessKeyId,
        secretAccessKey: credentials?.SecretKey,
        sessionToken: credentials?.SessionToken ?? '',
      },
    };
  },
  confirmNewPassword: async (
    username: string,
    newPassword: string,
    session: string,
  ): Promise<ChangePasswordCommandOutput> => {
    return cognito.send(
      new RespondToAuthChallengeCommand({
        ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
        ClientId: cognitoAppClientId,
        Session: session,
        ChallengeResponses: {
          NEW_PASSWORD: newPassword,
          USERNAME: username,
        },
      }),
    );
  },
  confirmForgotPassword: async (
    code: string,
    username: string,
    password: string,
  ): Promise<ChangePasswordCommandOutput> => {
    return cognito.send(
      new ConfirmForgotPasswordCommand({
        ClientId: cognitoAppClientId,
        ConfirmationCode: code,
        Password: password,
        Username: username,
      }),
    );
  },
  signup: async (
    email: string,
    password: string,
  ): Promise<ChangePasswordCommandOutput> => {
    return cognito.send(
      new SignUpCommand({
        ClientId: cognitoAppClientId,
        Password: password,
        Username: email,
        UserAttributes: [
          {
            Name: 'email',
            Value: email,
          },
        ],
      }),
    );
  },
  checkIfAuthenticated: async (): Promise<boolean> => {
    const refreshT = localStorage.getItem('refresh-token');
    if (refreshT) {
      try {
        await authService.useRefreshToken(refreshT);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  },
  logout: () => {
    localStorage.clear();
  },
};

export const getIamCredentials = async () => {
  await authService.useRefreshToken();
  return iamCredentials;
};
