/* eslint-disable react/button-has-type */
import React, {MouseEventHandler, ReactNode} from 'react';

export const CancelButton: React.FC<{
  text: string;
  type: 'button' | 'submit';
  icon?: ReactNode;
  onClick: MouseEventHandler;
}> = ({text, icon, onClick, type}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2">
      {icon}
      {text}
    </button>
  );
};
