import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import {useNavigate} from 'react-router';
import {LockClosedIcon, UserIcon} from '@heroicons/react/24/outline';
import {cognito} from '../../utils/authentication';
import Input from '../../components/InputButton';
import {APP_CONFIG} from '../../config/config-builder';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import {useQueryMap} from '../../hooks/useQueryParams';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [showWarning, setShowWarning] = useState('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);
  const {
    params: {code, user},
  } = useQueryMap();
  const navigate = useNavigate();

  const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await cognito.send(
        new ConfirmForgotPasswordCommand({
          ClientId: APP_CONFIG.awsConfig.cognitoAppClientId,
          Username: user,
          ConfirmationCode: code,
          Password: newPassword,
        }),
      );
      setSuccessMessage([
        'Changed password success, you will be redirect to login page.',
      ]);
      setTimeout(() => {
        navigate('/auth/login');
      }, 3 * 1000);
    } catch (err) {
      const errorMsg = err!.toString().split(':');
      setErrorMessage([errorMsg[errorMsg.length - 1]]);
      setShowWarning('Change password failed. Please try again.');
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={changePassword}>
          <div>
            <Input
              label="New Password"
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              autoComplete="password"
              required
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Confirm change password
            </button>
          </div>
        </form>
        {showWarning && (
          <div className="pt-4">
            <ErrorMessage
              onDismiss={() => setShowWarning('')}
              errors={errorMessage}
            />
          </div>
        )}
        {successMessage.length > 0 && (
          <div className="pt-4">
            <SuccessMessage
              onDismiss={() => setSuccessMessage([])}
              messages={successMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
