import React, {Fragment} from 'react';
import {BaseDialogConfig} from '../types';
import {DialogLayout} from '../DialogLayout/DialogLayout';

export interface SubscriptionDialogProps extends BaseDialogConfig {
  link: string;
}

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  link,
}) => {
  return (
    <DialogLayout
      submitButton="Go to payment gateway"
      onSubmit={async (e: any) => {
        e.preventDefault();
        window.open(link, '_blank');
      }}
      title="You don’t have an active subscription">
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          To generate a diagram, you need to have an active subscription
          connected to your account
        </p>
      </div>
    </DialogLayout>
  );
};
